$color-blue-1: #015cf5;
$color-blue-2: #0e78db;
$color-blue-3: #0057ff;
// $color-blue-4: #161360;
$color-blue-4: #131a60;
$color-blue-5: #094bff;
$color-blue-6: #131a60;
$color-blue-7: #08337c;
$color-blue-8: #032886;
$color-blue-10: #0048ff;
$color-blue-11: #2d4185;
$color-blue-12: #3d55a6;
$color-blue-13: #202d72;

// in zeplin its with opacity rgba(105, 130, 201, 0.3) but i need in hex
$color-blue-hovered: #4a62af;

$color-blue-light: #2cb1e7;
$color-blue-light-2: #35b1e1;
$color-blue-light-3: #0eabdb;

$color-main: #04053c;
$color-purple: #433f9f;
$color-dark: #00011c;
$color-dark-2: #0b0a29;
$color-dark-keno: #1a0157;
$color-dark-bs: #00133c;
$color-dark-bs2: #000615;
$color-dark-keno2: #010318;


$color-keno-gradient-1: #f4dc55;
$color-keno-gradient-2: #fcc314;

$color-ler-gradient-1: #ffe000;
$color-ler-gradient-2: #ffbf00;

$color-text-light: #a2b6ef;
$color-text-light-keno: #ece3fb;

$color-error: #ff335a;
$color-valid: #5bE587;

$color-yellow-2: #dea640;

$color-gray: #151617;
$color-gray-dark: #0D0D0D;
