$breakpoint-1: 480px;
$breakpoint-2: 520px;
$breakpoint-3: 812px;
@mixin mobileMedia {
    @media (max-width: $breakpoint-1), (max-width: $breakpoint-3) and (max-height: $breakpoint-2) and (orientation: landscape) {
        @content;
    }
}

@mixin mobileMediaPortrait {
  @media (max-width: $breakpoint-1), (max-width: $breakpoint-3) and (max-height: $breakpoint-2) and (orientation: portrait) {
    @content;
  }
}

@mixin desktopMedia {
  @media (min-width: $breakpoint-1 + 1) {
    @content;
  }
}