@import "../../../scss/common";
.share {
  display: flex;
  &__link {
    position: relative;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin-right: 1.6rem;
    background: $color-blue-6;
    transition: 0.2s background ease-in-out;
    overflow: hidden;
    @include center;
    
    &_game_ler, &_game_ar, &_game_bj, &_game-cr, &_game_bc {
      background: rgba(255, 255, 255, 0.10);
    }

    @include mobileMedia {
      width: 3.2rem;
      height: 3.2rem;
      margin-right: 3.75vw;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      @include bg-gradient;
      z-index: 1;
      opacity: 0;
      transition: 0.3s opacity ease-in-out;
    }
  
    &_game_ler, &_game_ar, &_game_bj, &_game-cr, &_game_bc {
      &:after {
        background: #FFFFFF4D;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    svg {
      width: 1.6rem;
      height: auto;
      position: relative;
      z-index: 2;
      transition: 0.3s fill ease-in-out;
      fill: $color-text-light;
      
      @include mobileMedia {
        width: 1.4rem;
      }
    }
  
    &_game_ler, &_game_ar, &_game_bj, &_game-cr, &_game_bc {
      svg {
        fill: #FFFFFF4D;

      }
    }
    
    &:hover {
      &:after {
        opacity: 1;
      }
      svg {
        fill: white;
      }
    }
  }
}

