@import 'common';
@import "mixins/breakpoints";
@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato/Lato-Italic.woff2') format('woff2'),
    url('../../fonts/Lato/Lato-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato/Lato-Black.woff2') format('woff2'),
    url('../../fonts/Lato/Lato-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato/Lato-Thin.woff2') format('woff2'),
    url('../../fonts/Lato/Lato-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato/Lato-Bold.woff2') format('woff2'),
    url('../../fonts/Lato/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato/Lato-Regular.woff2') format('woff2'),
    url('../../fonts/Lato/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato/Lato-Medium.woff2') format('woff2'),
    url('../../fonts/Lato/Lato-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/Gilroy/Gilroy-Regular.woff2') format('woff2'),
    url('../../fonts/Gilroy/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/Gilroy/Gilroy-Medium.woff2') format('woff2'),
    url('../../fonts/Gilroy/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/Gilroy/Gilroy-Bold.woff2') format('woff2'),
    url('../../fonts/Gilroy/Gilroy-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('../../fonts/PTRootUI/PTRootUI-Bold.woff2') format('woff2'),
    url('../../fonts/PTRootUI/PTRootUI-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('../../fonts/PTRootUI/PTRootUI-Light.woff2') format('woff2'),
    url('../../fonts/PTRootUI/PTRootUI-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('../../fonts/PTRootUI/PTRootUI-Medium.woff2') format('woff2'),
    url('../../fonts/PTRootUI/PTRootUI-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('../../fonts/PTRootUI/PTRootUI-Regular.woff2') format('woff2'),
    url('../../fonts/PTRootUI/PTRootUI-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/Gilroy/Gilroy-SemiBold.woff2') format('woff2'),
  url('../../fonts/Gilroy/Gilroy-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.page {
  background: $color-main;

  &__container {
    min-height: calc(var(--vh) * 100);
    display: flex;
    flex-direction: column;
  }

  &__main-container {
    margin-bottom: 23.2rem;
  }
}
:root {
  --scrollBarVisibility: 0;
}

html,
body {
  height: 100%;
  min-height: 100%;
  background: linear-gradient($color-main, $color-main);
  
  @include mobileMedia {
    background: #010728;
  }
}
    
body::-webkit-scrollbar {
  width: var(--scrollBarVisibility);
}

* {
  scroll-behavior: smooth;
}
.d-none{
  display: none;
}
.view-height{
  height: 100%;
  min-height: 100%;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;

  @media (max-width: 1336px) {
    font-size: 8px;
  }
  
  @media (max-width: 1120px) {
    font-size: 7px;
  }
  
  
  @media (max-width: $breakpoint-1), (max-width: $breakpoint-3) and (max-height: $breakpoint-2) and (orientation: landscape) {
    font-size: 10px;
  }

  @media (max-width) {
    font-size: 9px;
  }
}

body {
  height: 100%;
  min-height: calc(var(--vh) * 100);
  margin: 0;
  font-family: Lato, Arial, sans-serif;
  overflow-x: hidden;

  * {
    box-sizing: border-box;
  }
}
[tabindex='-1']:focus {
  outline: none !important; // stylelint-disable-line
}

// Коррекция для кликабельных элементов с указанной ролью в iOS
[role='button'] {
  cursor: pointer;
}

// Мы любим Сафари (на самом деле, нет).
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; // stylelint-disable-line
}

// area,
// summary,
a,
button,
[role='button'],
input,
label,
select,
textarea {
  touch-action: manipulation; // Стилевая коррекция 300ms задержки (для поддерживающих браузеров)
  margin: 0; // Убираем margin в Firefox и Safari
}

button,
input {
  overflow: visible; // Покажем торчащее в Edge
  text-transform: none; // Уберём наследование text transform в Firefox
}

// Коррекция проблем стилизации для Safari
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; // stylelint-disable-line
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

// Коррекция для Mobile Safari (https://github.com/twbs/bootstrap/issues/11266)
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox; // stylelint-disable-line
}

textarea {
  overflow: auto; // Удаление верт. скролла в IE.
  resize: vertical; // Правильное изменение размеров
}

svg {
  fill: currentColor;

  a &,
  button & {
    pointer-events: none; // Мы любим Edge, это браузер! (почти)
  }

  &:not(:root) {
    overflow: hidden; // Скрыть выступающее за границы в IE
  }
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  background-color: transparent;
  text-decoration: none;

  &:hover,
  &:focus {
    @media (pointer: fine) {
      color: inherit;
    }
  }
}

hr {
  box-sizing: content-box; // Проблема Firefox
  height: 0; // Проблема Firefox
  overflow: visible; // Проблема Edge и IE
  border: 0;
  border-top: 1px solid inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
}

p {
  margin: 0;
  padding: 0;
}

address {
  font-style: normal;
}

ul,
ol {
  margin-bottom: 0;
  padding-left: 1.2em;

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
  }
}

li {
  line-height: inherit;
}

// dt {
//   font-weight: 700;
// }

// dd {
//   margin-left: 0;

//   @media (min-width: $screen-sm) {
//     margin-left: 1.5rem;
//   }

//   & + dt {
//     margin-top: ($line-height / 2);
//   }
// }

// .small,

// .mark,
// mark {
//   background: rgba($color-warning, 0.3);
//   padding: 0.1em 0.3em;
// }

// abbr[title] {
//   text-decoration: underline dotted;
// }

blockquote {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

.big {
  font-size: 2rem;
}

button {
  padding: 0;
  outline: none;
  background: inherit;
  font-family: inherit;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

button:hover,
button:focus,
button:active {
  border: none;
  outline: none;
  box-shadow: none;
}

.part__title-container {
  @include container;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobileMedia {
    flex-direction: row;
    margin-bottom: 1.6rem;
  }

  & .part__title {
    @include title-2;
    margin-bottom: 0.5rem;
    text-transform: uppercase;

    @include mobileMedia {
      margin-bottom: 0;
      margin-right: auto;
    }
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
