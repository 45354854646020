@import '../../../scss/common';

.arrow_prev.arrow_game_bc {
  &:after {
    background: linear-gradient(90deg, #7F4C00 -32%, #E58900 100%);
  }
  &:before {
    background: linear-gradient(90deg, #7F4C00 -32%, #E58900 100%);
  }
}

.arrow {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 50%;
  @include center;
  &:after, &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    @include bg-gradient;
    transition: 0.3s transform ease-in-out;
  }
  
  &:before {
    opacity: 0;
    z-index: 2;
    transition: 0.3s transform ease-in-out, 0.3s opacity ease-in-out;
  }
  
  &_game {
    &_keno {
      &:after {
        background-image: linear-gradient(to bottom, #ffd000, #ffbf00);
      }
  
      &:before {
        background-image: linear-gradient(to bottom, #ffd000 29%, #ffbf00);
      }
    }
  
    &_ler, &_ar {
      &:after {
        background-image: linear-gradient(90deg, #FFD000 -14.79%, #FFBF00 120.89%);
      }
  
      &:before {
        background-image: linear-gradient(90deg, #FFD000 -14.79%, #FFBF00 120.89%);
      }
    }

    &-cr {
      &:after {
        background: linear-gradient(180deg, #03E9EB 0%, #0A869D 100%);
      }
  
      &:before {
        background: linear-gradient(180deg, #03E9EB 0%, #0A869D 100%);
      }
    }
  
    &_bj {
      &:after {
        background: linear-gradient(90deg, #946F2F 0%, #C4A163 78.65%, #DBAC5A 100%);
      }
  
      &:before {
        background: linear-gradient(90deg, #946F2F 0%, #C4A163 78.65%, #DBAC5A 100%);
      }
    }
    &_bc {
      &:after {
        background: linear-gradient(270deg, #7F4C00 -32%, #E58900 100%);
      }

      &:before {
        background: linear-gradient(270deg, #7F4C00 -32%, #E58900 100%);
      }

      &.arrow_prev {
        &:after {
          background: linear-gradient(90deg, #7F4C00 -32%, #E58900 100%); 
        }

        &:before {
          background: linear-gradient(90deg, #7F4C00 -32%, #E58900 100%); 
        }
      }
    }
  }
  
  
  &_disable {
    cursor: unset;
    &:after,&:before {
      @include bg-gradient-dark;
    }
    .arrow__icon {
      opacity: 0.5;
      svg {
        fill: white;
      }
    }
    &.arrow_game {
      &_keno {
        &:after,&:before {
          background: #1a0157;
        }
      }
  
      &_ler, &_ar, &_bj, &_bc {
        &:after,&:before {
          background: rgba(#333333, 0.3);
        }
      }

      &-cr {
        &:after,&:before {
          background: rgba(51, 51, 51, 1);
        }
      }
    }
  }
  
  &:not(.arrow_disable){
    &:hover {
      &:after, &:before {
        transform: scale(1.16);
        @include mobileMedia {
          transform: scale(1.1)
        }
      }
      
      &:before{
        opacity: 1;
      }
    }
  }
  

  &__icon {
    position: relative;
    z-index: 3;
    @include svg;
    @include center;
    svg {
      fill: white;
      height: 1.6rem;
      width: 1.6rem;

      .arrow__small & {
        width: 0.5rem;
      }


      .arrow_prev & {
        transform: rotate(180deg);
      }
    }
    
    &_game {
      &_ler, &_ar {
        svg {
          fill: #000000;
        }
      }
    }
  }
}
