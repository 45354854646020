@import "../../scss/common";

.CR-table {
  &-wrapper {
    overflow-x: auto;
    border-radius: 4px;

    @include mobileMedia {
      width: 100vw;
      margin-left: -1.6rem;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }

  color: rgba(255, 255, 255, 0.9);
  width: 840px;
  border-collapse: collapse;
  text-align: left;
  border-spacing: 0;

  & th,
  td {
    padding: 16px;
  }
  & thead {
    background-color: rgba(23, 23, 23, 1);
    font-family: Gilroy;
    font-weight: 600;
  }

  & tbody {
    & tr:nth-child(even) {
      background: rgba(23, 23, 23, 0.6);
    }
  }
}

.CR-rules-img {
  &:last-of-type {
    margin-bottom: 4.8rem;
  }
}

.CR-rules-img-mobile {
  text-align: center;
  margin: 0 auto;
  @include mobileMedia {
    content: url("../../img/rules/cr/rules-2-mobile.png");
  }
}
