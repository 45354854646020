@import '../../../../scss/common';

.item-news-list {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  cursor: pointer;
  background: $color-blue-6;
  border-radius: 0.4rem;
  overflow: hidden;
  transition: 0.3s background ease-in-out;
  
  &:hover {
    @media (pointer: fine) {
      background: $color-blue-13;
    }
  }

  &__top {
    height: 24rem;
    position: relative;
    @include mobileMedia {
      height: 19.2rem;
    }
  }
  &__img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;

    @include mobileMedia {
      object-position: top;
    }
  }

  &__type {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba($color-blue-4, 0.7);
    height: 4rem;
    padding: 0 2.4rem;
    white-space: nowrap;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 0.15rem;
    border-top-right-radius: 0.4rem;
    @include center;
    text-transform: uppercase;
    color: white;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2.4rem;

    @include mobileMedia {
      padding: 2.4rem 1.6rem 3.2rem;
    }
  }

  &__date {
    opacity: 0.8;
    font-family: Lato, Arial, sans-serif;
    font-size: 1.4rem;
    line-height: 1.71;
    letter-spacing: 0.44px;
    color: $color-text-light;
    margin-bottom: 1.6rem;

    @include mobileMedia {
      font-size: 1.4rem;
      margin-bottom: 0.8rem;
    }
  }

  &__title {
    font-family: Gilroy, Arial, sans-serif;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 1.45;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 1.6rem;

    @include mobileMedia {
      margin-bottom: 0.8rem;
    }
  }

  &__text {
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: $color-text-light;
  }
}
