@import "../../../scss/common";

.logo-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  overflow-x: hidden;
  background: $color-main;
  will-change: transform;

  &__container {
    width: 30rem;
    height: 30rem;
    position: relative;
    overflow: hidden;

    @include mobileMedia {
      width: 15rem;
      height: 15rem;
    }
  }

}

.logo-loader__animation {
  width: 30rem;
  height: 30rem;
  position: absolute;
  top: 0;
  left: 0;

  @include mobileMedia {
    width: 15rem;
    height: 15rem;
  }

  & .animation__slide {
    display: block;
    width: 30rem;
    height: 30rem;

    @include mobileMedia {
      width: 15rem;
      height: 15rem;
    }
  }
}

.logo-loader__container-exit {
  opacity: 0;
  width: 30rem;
  height: 30rem;
  position: relative;
  overflow: hidden;
  transform: translateX(-140%);
  transition: transform 500ms cubic-bezier(0.23, 0, 0.5, 1), opacity 200ms cubic-bezier(0.5, 0, 0.5, 1);

  @include mobileMedia {
    width: 15rem;
    height: 15rem;
    transform: translateX(-30%);
  }
}

.logo-loader-enter {
  transform: translateX(0%);
}

.logo-loader-enter-active {
  transform: translateX(0%);
}

.logo-loader-exit {
  transform: translateX(0%);
  transition: transform 500ms cubic-bezier(0.5, 0, 0.5, 1);
}

.logo-loader-exit-active {
  transform: translateX(100%);
  transition: transform 500ms cubic-bezier(0.5, 0, 0.5, 1);
}
