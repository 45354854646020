@import '../../../scss/common';

.popup-modal {
    padding: 5.6rem 7.2rem;
    background-color: $color-blue-4;
    border-radius: 0.4rem;

    @include mobileMedia {
      padding: 3.2rem 4.8rem;
      // 7rem header height
      scroll-margin-top: 7rem;

    }
    

    &-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 3.2rem;
        border-radius: 50%;
        width: 5.6rem;
        height: 5.6rem;
        background: linear-gradient(to right, $color-blue-light-3, #018ef5);

        & svg {
            width: 2.4rem;
            height: 2.4rem;
        }

        @include mobileMedia {
          margin-right: 1.6rem;
          width: 3.2rem;
          height: 3.2rem;

          & svg {
            width: 1.6rem;
            height: 1.6rem;
          }
        }
    }

    &-title {
        @include  subtitle;
        display: inline-block;
        color: #ffffff;
    }

    &-text {
        font-family: Lato, Arial, sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.02rem;
        margin-top: 4.8rem;
        color: $color-text-light;

        @include mobileMedia {
          margin-top: 1.6rem;
          font-size: 1.4rem;
        }
    }
}