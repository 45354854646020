@import "../../../scss/common";

.partnership-top{
  position: relative;
  padding: 14.9rem 0 6.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mobileMedia {
    padding: 3.2rem 0 2.4rem;
  }

  &__container {
    @include container;
    position: relative;
    z-index: 2;
  }
  &__title {
    @include title-5;
    color: white;

    line-height: 1.17;
    letter-spacing: 0.5rem;
    text-align: center;
    @include mobileMedia {
      text-align: start;
    }
  }
}

