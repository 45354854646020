@import '../../../scss/common';

.partnership-form {
  &__container {
    max-width: 84rem;
    margin: 0 auto;
    @include mobileMedia {
      margin: 0 1.6rem;
    }
  }

  &__form {
    border-radius: 0.4rem;
    background-color: $color-blue-6;
    padding: 6.4rem 5.6rem 4.8rem;

    @include mobileMedia {
      padding: 4rem 1.6rem;
      margin-bottom: 8rem;
    }
  }

  &__title {
    @include subtitle;
    color: white;
    margin-bottom: 4.8rem;

    @include mobileMedia {
      margin-bottom: 2.4rem;
    }
  }

  &__fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr 2fr;
    grid-gap: 2.4rem;

    @include mobileMedia {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
      grid-auto-rows: 2fr;
      grid-gap: 1.6rem;
    }
  }

  &__field {
    @include field;

    &.is__textarea {
      grid-area: 3 / 1 / 4 / 3;

      @include mobileMedia {
        grid-area: unset;
      }
    }

    &__combo {
      display: flex;

      @include mobileMedia {
        display: block;

        & .input-field {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          backface-visibility: hidden;
          transform: translate3d(0, 0, 0)
        }

      }
      
    }
  }

  &__input {
    @include input;
  }
  &__btn {
    height: 5.6rem;
    width: 40.8rem;
    margin: 0 auto 0.1rem;

    @include mobileMedia {
      width: 100%;
      height: 4.8rem;
    }
  }

  &__privacy {
    margin-top: 2.8rem;
    margin-bottom: 5.4rem;

    @include mobileMedia {
      margin-top: 1.6rem;
      margin-bottom: 4rem;
    }
  }

  &__label {
    position: relative;
    display: flex;
  }

  &__input-check {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__check {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.4rem;
    background: $color-blue-11;
    transition: 0.2s background ease-in-out;
    margin-right: 1.6rem;
    .partnership-form__input-check:checked ~ & {
      background: url('../../../img/check-active.svg') $color-blue-11;
    }
  }

  &__label-text {
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
    color: white;
    transition: 0.2s color ease-in-out;
    .contacts-form__input-check:checked ~ & {
      color: $color-text-light;
    }
  }
}
