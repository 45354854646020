@import "../../../scss/common";

.btn {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 0.4rem;
  @include bg-gradient;
  color: white;
  border: none;
  text-transform: uppercase;
  font-family: Gilroy, Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.71;
  letter-spacing: 0.1rem;
  transition: 0.3s background-color ease-in-out, 0.3s color ease-in-out;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include bg-gradient-light;
    opacity: 0;
    transition: 0.2s opacity ease-in-out;
    z-index: 1;
  }

  &__text {
    position: relative;
    z-index: 2;
    opacity: 1;
    white-space: nowrap;
  }

  &:disabled {
    @include bg-gradient-dark;
    .btn__text {
      opacity: 0.5;
    }

    &:hover {
      cursor: not-allowed;

      &:disabled::after {
        opacity: 0;
      }
    }
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  &__is__small {
    @include mobileMedia {
      font-size: 1rem;
      font-weight: 800;
      line-height: 1.6;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_type-1 {
    &_game_keno {
      background-image: linear-gradient(
        to right,
        $color-keno-gradient-1,
        $color-keno-gradient-2
      );
      color: #161360;
      &:after {
        box-shadow: 0 0 1rem 0 rgba(249, 205, 45, 0.6);
        background-image: linear-gradient(
          to right,
          $color-keno-gradient-1 45%,
          $color-keno-gradient-2 108%
        );
      }
    }

    &_game_ler {
      background: linear-gradient(to bottom, #ffd000, #ffbf00);
      color: #000000;
      &:after {
        box-shadow: 0 0 1rem 0 rgba(255, 224, 0, 0.6);
        background: linear-gradient(to bottom, #ffd000 -26%, #ffd000 117%);
      }
    }

    &_game_ar {
      background: linear-gradient(270deg, #ffd000 0%, #ffbf00 97.5%);
      color: #000000;
      &:after {
        background: linear-gradient(270deg, #ffd000 0%, #ffd000 97.5%);
        box-shadow: 0px 0px 10px 0.6px rgba(255, 224, 0, 0.5);
      }
    }

    &_game_bj {
      background: linear-gradient(
        90deg,
        #946f2f 0%,
        #c4a163 78.65%,
        #dbac5a 100%
      );
      color: #ffffff;
      &:after {
        background: linear-gradient(
          90deg,
          #946f2f 0%,
          #c4a163 78.65%,
          #dbac5a 100%
        );
        box-shadow: 0px 0px 10px 0.6px rgba(255, 224, 0, 0.5);
      }
    }

    &_game-cr {
      background: linear-gradient(180deg, #03e9eb 0%, #0a869d 100%);
      color: #ffffff;
      &:after {
        background: linear-gradient(180deg, #03e9eb 0%, #0a869d 100%);
      }
      &:hover {
        box-shadow: 0px 0px 10px 0px rgba(8, 164, 181, 0.7);
      }
    }

    &_game_bc {
      background: linear-gradient(270deg, #7F4C00 -32%, #E58900 100%);
      color: #ffffff;
      &:after {
        background: linear-gradient(270deg, #9B5D00 -32%, #FF9900 100%);
        box-shadow: 0px 0px 10px 0.6px rgba(255, 224, 0, 0.5);
      }
    }
  }

  &_type-2 {
    @include bg-gradient-dark;
    &:after {
      @include bg-gradient-dark-hover;
    }

    &_game_keno {
      background: #330286;
      &:after {
        background-image: linear-gradient(
          to right,
          $color-keno-gradient-1,
          $color-keno-gradient-2
        );
      }
      &:hover {
        @media (pointer: fine) {
          color: #161360;
        }
      }
    }

    &_game_ler {
      background: #333333;
      color: #ffffff;
      &:after {
        background: linear-gradient(to bottom, #ffd000, #ffbf00);
      }
      &:hover {
        @media (pointer: fine) {
          color: #000000;
        }
      }
    }

    &_game_ar {
      background: linear-gradient(270deg, #ffd000 0%, #ffbf00 97.5%);
      color: #000000;
      &:after {
        background: linear-gradient(to bottom, #ffd000, #ffbf00);
        box-shadow: 0px 0px 10px 0.6px rgba(255, 224, 0, 0.5);
      }
      &:hover {
        @media (pointer: fine) {
          color: #000000;
        }
      }
    }

    &_game_bj {
      background: linear-gradient(
        90deg,
        #946f2f 0%,
        #c4a163 78.65%,
        #dbac5a 100%
      );
      color: #ffffff;
      &:after {
        background: linear-gradient(
          90deg,
          #946f2f 0%,
          #c4a163 78.65%,
          #dbac5a 100%
        );
        box-shadow: 0px 0px 10px 0.6px rgba(255, 224, 0, 0.5);
      }
      &:hover {
        @media (pointer: fine) {
          color: #000000;
        }
      }
    }

    &_game-cr {
      background: linear-gradient(180deg, #03e9eb 0%, #0a869d 100%);
      color: #ffffff;
      &:after {
        background: linear-gradient(180deg, #03e9eb 0%, #0a869d 100%);
      }
      &:hover {
        box-shadow: 0px 0px 10px 0px rgba(8, 164, 181, 0.7);
      }
      @include mobileMedia {
        background: rgba(21, 22, 23, 1);
      }
    }
    &_game_bc {
      background: linear-gradient(270deg, #7F4C00 -32%, #E58900 100%);
      color: #ffffff;
      &:after {
        background: linear-gradient(270deg, #9B5D00 -32%, #FF9900 100%);
        box-shadow: 0px 0px 10px 0.6px rgba(255, 224, 0, 0.5);
      }
    }
  }
}
