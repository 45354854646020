@import "../../../scss/common";

.footer {
  background: $color-dark;

  &_game_ler,
  &_game_ar,
  &_game_bj,
  &_game-cr,
  &_game_bc{
    background: #0d0d0d;
  }

  &_game-cr, &_game_bc {
    position: relative;
    z-index: 1;
  }

  &_game_bc {
    position: relative;
    z-index: 1;
  }

  @include mobileMedia {
    overflow: hidden;
  }

  &__top {
    padding: 3.2rem 0;
    border-bottom: 0.1rem solid rgba(19, 26, 96, 0.5);
    width: var(--marquee-width);
    overflow: hidden;

    &_game_ler,
    &_game_ar,
    &_game_bj,
    &_game-cr,
    &_game_bc{
      border-bottom: 0.1rem solid rgba(255, 255, 255, 0.05);
    }
  }

  &__partner {
    background: #040629;
    border-radius: 8px;
    height: 9.6rem;
    width: var(--marquee-element-width);
    margin-right: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    flex-shrink: 0;

    & svg {
      & g {
        opacity: 1;
      }
      & path {
        fill: #535e8c;
        fill-opacity: 1;
      }
    }

    &-white {
      background: #161616;
      border-radius: 8px;
      height: 9.6rem;
      width: var(--marquee-element-width);
      margin-right: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      flex-shrink: 0;

      & svg {
        & g {
          opacity: 1;
        }
        & path {
          fill: #5b5b5b;
          fill-opacity: 1;
        }
      }
    }
  }

  &__template {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 2.4rem;

    @include mobileMedia {
      display: block;
    }
  }

  &__main {
    margin: 4.2rem auto 3.75rem;
    padding: 0 4.7rem;
    max-width: 136.6rem;

    @include mobileMedia {
      padding: 3.2rem 0 3.2rem;
      margin: 0;
    }
  }

  &__logo {
    width: 6rem;
    height: 4rem;
    background: url("../../../img/logo.svg") no-repeat center;
    background-size: cover;
    justify-self: flex-start;

    &_game_ler,
    &_game_ar,
    &_game_bj,
    &_game_bc{
      background-image: url("../../../img/logo-footer-ler.svg");
    }

    @include mobileMedia {
      width: 6rem;
      height: 4rem;
      background: none;
      margin-top: 3.4rem;

      & svg {
        width: 100%;
      }
    }
  }

  &__columns {
    @extend .footer__template;
    justify-items: center;

    @include mobileMedia {
      display: flex;
      padding: 0 1.6rem;
      gap: 9rem;
    }
  }

  &__col {
    &_big {
      grid-area: 1/ 4/ 2/ 7;
      display: grid;
      padding-bottom: 4rem;
    }

    @include mobileMedia {
      &.footer__col_2,
      &.footer__col_1 {
        display: none;
      }
    }
  }

  &__item {
    min-height: 2.4rem;
    display: block;
    @include text-1;
    @include text-hover-bright;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    font-family: Gilroy, "Arial Black", sans-serif;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: normal;

    @include mobileMedia {
      margin-top: 0;
      margin-bottom: 0.8rem;
      width: 30%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    cursor: pointer;

    &:first-child {
      margin-top: 0;
    }

    &_game_ler,
    &_game_ar,
    &_game_bj,
    &_game_bc{
      @include text-hover-bright-yellow;
    }

    &_game-cr {
      &:hover,
      :active {
        color: #03e9eb;
      }
    }

    &.not__link {
      cursor: default;
      color: white;

      @include mobileMedia {
        display: none;
      }
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;

    @include mobileMedia {
      .footer__col_1 & {
        display: none;
      }
    }
  }

  &__list-item {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.29;
    cursor: pointer;
    @include text-hover-turn;

    &_game_ler,
    &_game_ar,
    &_game_bj,
    &_game-cr,
    &_game_bc{
      color: rgba(white, 0.65);

      &:hover {
        @media (pointer: fine) {
          color: white;
        }
      }
    }

    &_disabled {
      pointer-events: none;
    }
  }

  &__list-link {
    display: block;
    white-space: nowrap;
  }

  &__partners {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    grid-template-rows: repeat(2, 2.5rem);
    column-gap: 6.4rem;
    align-content: space-between;
    height: 100%;

    @include mobileMedia {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 5rem;
      grid-column-gap: 5rem;
      padding: 5rem 1.4rem 0;
    }
  }

  &__bottom {
    border-top: 0.1rem solid rgba(19, 26, 96, 0.5);

    &_game_ler,
    &_game_ar,
    &_game_bj,
    &_game-cr,
    &_game_bc{
      border-top: 0.1rem solid rgba(255, 255, 255, 0.05);
    }

    &-contain {
      display: grid;
      height: 7.2rem;
      align-items: center;
      justify-content: flex-start;
      gap: 6.8rem;
      margin: 0 auto;
      padding: 0 4.7rem;
      max-width: 136.6rem;

      @include mobileMedia {
        position: relative;
        padding: 2.4rem 0;
        height: unset;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 1.6rem;
      }
    }
  }

  &__copy {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.4rem;
    color: #a2b6ef;
    opacity: 0.6;
    font-family: "Lato";
    grid-column: 1;

    &_game_ler,
    &_game_ar,
    &_game_bj,
    &_game-cr,
    &_game_bc{
      color: #ffffff;
      opacity: 0.4;
    }
  }

  // &__info {
  //   // TODO WHEN RETURN COOKIES AND CONDITIONS
  //   // grid-area: 1/ 3/ 2/ 6;

  //   // start temporarily code
  //   grid-area: 1/ 2/ 2/ 3;
  //   //  end of temporarily code

  //   display: flex;
  //   align-items: center;
  //   grid-area: 1/ 3/ 2/ 4;

  //   @include mobileMedia {
  //     margin-bottom: 1.6rem;
  //   }
  // }

  &__info-item {
    margin-right: 3.2rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.4rem;
    font-family: "Lato";
    @include text-hover-turn;
    color: #a2b6ef;
    grid-column: 2;

    &:last-child {
      margin-right: 0;
    }

    &_game_ler,
    &_game_ar,
    &_game_bj,
    &_game-cr,
    &_game_bc{
      @include text-hover-turn;
      color: #ffffffa6;
    }

    @include mobileMedia {
      position: absolute;
      top: 1rem;
      margin: 0;
      color: #a2b6ef;
      font-size: 1.4rem;
    }
  }

  &__lang {
    grid-area: 1/ 6/ 2/ 7;
    display: flex;
    justify-content: flex-end;

    @include mobileMedia {
      position: absolute;
      top: 2.4rem;
      align-self: flex-end;
    }
  }

  &__button {
    width: 19.2rem;
    height: 4.8rem;
    margin-left: 7.2rem;

    @include mobileMedia {
      margin: 0 1.6rem 3.2rem;
      width: auto;
    }
  }

  &__mail {
    color: #a2b6ef;
    width: 11rem;
    height: 2.4rem;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: 2.4rem;

    &_game_ler,
    &_game_ar,
    &_game_bj,
    &_game-cr,
    &_game_bc{
      color: #ffffffa6;
      width: 11rem;
      height: 2.4rem;
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.4rem;
      margin-bottom: 2.4rem;
    }
  }
}

.footer-ticker {
  --marquee-width: 100vw;
  --marquee-height: 20vh;
  --marquee-elements: 14;
  --marquee-elements-displayed: 7.5;
  --marquee-element-width: calc(
    var(--marquee-width) / var(--marquee-elements-displayed)
  );
  --marquee-animation-duration: calc(var(--marquee-elements) * 6s);

  @include mobileMedia {
    --marquee-elements-displayed: 2;
  }
  display: flex;
  animation: scrolling var(--marquee-animation-duration) linear infinite;
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(
      calc(
        -1 * var(--marquee-element-width) * var(--marquee-elements) - (2.4rem *
              14)
      )
    );
  }
}

.share__link {
  @include mobileMedia {
    width: 4rem !important;
    height: 4rem !important;
  }
}
