@import "../../scss/common";

.page-404 {

    
    width: 100%;
    background-color: $color-main;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    &-wrapper {
        // 7rem header height
        height: calc(80rem - 7rem);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.6rem;
        flex-grow: 1;

        @include mobileMedia {
            height: calc(64rem - 7rem);
        }
    }

    &-image svg {
        width: 297px;
        height: 134px;

        @include mobileMedia {
            width: 158px;
            height: 71px;
        }
    }




    &-title {
        @include title-2;
        text-transform: uppercase;
        margin: 7.2rem 0 3.2rem;
        text-align: center;

        @include mobileMedia {
            margin: 5.6rem 0 2.4rem;
        }
    }

    &-text {
        font-family: Lato, Arial, sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.5;
        color: #ffffff;
    }

    &-link {
        color: #0048ff;
    }
}