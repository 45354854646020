@import "../../../scss/common";
.header {
  position: fixed;
  width: 100%;
  height: 8rem;
  z-index: 100;
  display: flex;
  background-color: rgba(19, 26, 96, 0);
  transition: background-color 0.3s ease-in;
  &__colored {
    background-color: $color-blue-6;
    transition: background-color 1s ease-in-out !important;
    &_game_ler,
    &_game_ar,
    &_game_bj,
    &_game-cr,
    &_game_bc{
      background-color: $color-gray-dark;
    }
  }

  @include mobileMedia {
    // background-color: $color-main;
    background-color: $color-blue-6;
    top: 0;
    transition: top 0.3s;
    &_game_ler,
    &_game_ar,
    &_game_bj,
    &_game-cr,
    &_game_bc{
      background-color: $color-gray-dark;
    }

    // &.is__alt {
    // background-color: unset;
    // transition: background-color 0s;
    // }

    // &__colored.is__alt {
    &__colored {
      top: -8rem;
      transition: top 0.3s ease-in-out !important;
    }
  }

  --burgerPadding: 3.2rem;

  .header__inner {
    @include container;
    display: flex;
    align-items: center;
    position: relative;
  }

  &__burger {
    display: flex;
    align-items: center;
    width: 100%;
    transition: left 0.45s ease-out;

    @include mobileMedia {
      position: fixed;
      top: 8rem;
      left: 200vw;
      height: calc(100% - 8rem);
      width: 100vw;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 2rem;
      background-color: $color-blue-6;
    }

    &.is__open {
      left: 0;
    }
  }

  &__burger__toggler {
    position: relative;
    display: none;

    &:hover {
      cursor: pointer;
    }

    @include mobileMedia {
      display: block;
      width: 3rem;
      height: 3rem;
      padding: 0.3rem;
      z-index: 200;

      &::after,
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 2.4rem;
        height: 0.2rem;
        background-color: #fff;
        transition: 0.4s ease-in-out;
      }

      &::before {
        top: calc(50% - 0.6rem);
      }

      &::after {
        top: calc(50% + 0.6rem);
      }

      &.is__open {
        &::before {
          transform: rotate(45deg);
          transform-origin: 0;
          top: calc(50% - 0.8rem);
        }

        &::after {
          transform: rotate(-45deg);
          transform-origin: 0;
          top: calc(50% + 0.9rem);
        }
      }
    }
  }

  &__logo {
    width: 17.7rem;
    height: 2.8rem;
    margin-right: 4.7rem;
    flex-shrink: 0;

    @include desktopMedia {
      background-image: url("../../../img/logo-header-def.svg");
      background-repeat: no-repeat;
      background-position: center left;
      background-size: cover;
    }

    &_game_ler,
    &_game_ar,
    &_game_bj,
    &_game_bc{
      @include desktopMedia {
        background-image: url("../../../img/logo-header-ler.svg");
      }
    }

    &.mobile {
      background: unset;
      background-size: unset;
    }

    @include mobileMedia {
      width: 4.8rem;
      height: 3.4rem;
      margin-right: auto;

      & svg {
        width: 100%;
      }
    }
  }

  &__nav {
    display: flex;
    font-family: Gilroy, Arial, sans-serif;

    @include mobileMedia {
      flex-direction: column;
    }
  }

  &.is__open &__nav-element {
    animation: opacity 0.75s ease-out forwards;
    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        animation-delay: 0.05s + $i * 0.15s;
      }
    }
  }

  &__nav-element {
    height: 8rem;
    position: relative;
    @include center;
    margin: 0 1.8rem;
    color: white;
    cursor: pointer;
    transition: all 0.4 ease-in;

    @include mobileMedia {
      margin: 0;
      height: unset;
      line-height: 1.45;
      margin-top: 1.6rem;
      opacity: 0;
      justify-content: start;
      padding-left: var(--burgerPadding);

      &:first-child {
        margin-top: 2rem;
      }
    }

    &:after {
      content: "";
      display: block;
      top: 0;
      position: absolute;
      width: 100%;
      height: 0.4rem;
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      @include bg-gradient;
      transform: scale(0);
      z-index: 2;

      @include mobileMedia {
        top: unset;
        left: -0.4rem;
        height: 3.2rem;
        width: 0.8rem;
        border-top-right-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
      }
    }

    &_game {
      &_keno {
        &:after {
          background: linear-gradient(270deg, #ffd000 0%, #ffd000 97.5%);
          box-shadow: 0px 0px 10px 0.6px rgba(255, 224, 0, 0.5);
        }
      }

      &_bj {
        &:after {
          background: linear-gradient(
            90deg,
            #dcb46d 0%,
            #c4a163 42.19%,
            #946f2f 100%
          );
        }
      }

      &-cr {
        &:after {
          background: linear-gradient(180deg, #03e9eb 0%, #0a869d 100%);
        }
      }

      &_ar,
      &_ler {
        &:after {
          background: linear-gradient(to bottom, #ffd000 -26%, #ffd000 117%);
        }
      }
      &_bc {
        &:after{
        background: linear-gradient(90deg, #FFC164 0%, #E58900 42.19%, #7F4C00 100%);}
      }
    }

    &_active {
      &:after {
        animation-name: onNavClick;
        animation-fill-mode: forwards;
        animation-duration: 0.3s;
        animation-timing-function: ease-out;
        transform: scale(1);
      }
    }
  }

  &__nav-link {
    display: flex;
    height: 100%;
    align-items: center;
    @include text-1;
    @include text-hover-bright;
    text-transform: uppercase;

    &_game_ler,
    &_game_ar,
    &_game_bj,
    &_game_bc {
      @include text-hover-bright-yellow;
    }

    &_game-cr {
      &:hover {
        color: rgba(#ffffff, 0.8);
      }
    }

    @include mobileMedia {
      font-size: 2.2rem;
    }
  }

  &__lang {
    margin-left: auto;
    margin-right: 2.4rem;

    @include mobileMedia {
      margin: 2rem 0 4rem var(--burgerPadding);
    }
  }

  &__login {
    width: 16rem;
    height: 4.8rem;

    @include mobileMedia {
      margin-left: var(--burgerPadding);
      margin-right: var(--burgerPadding);
      width: calc(100% - 2 * var(--burgerPadding));
    }
  }
}

@keyframes onNavClick {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes opacity {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0rem);
  }
}
