@import "../../../scss/common";


.partnership-slider {

  --border-radius-small: 0.8rem;

  @include mobileMedia {
      --border-radius-small: 0.4rem;
  }

  &__container {
    @include container;
    display: flex;

    @include mobileMedia {
      display: block;
    }
  }

  &__arrows {
    position: absolute;
    display: flex;
    bottom: 4rem;
    right: 4rem;
    z-index: 2;
  }
  
  &__arrow {
    height: 4.8rem;
    width: 4.8rem;
    margin-right: 1.6rem;
    &:last-child {
      margin-right: 0;
    }
  }

  &__info, &__media {
    width: 50%;
    height: 46.8rem;
    overflow: hidden;
  
    .swiper-wrapper {
      transition-timing-function: cubic-bezier(0.65,0.05,0.36,1) !important;
    }

    @include mobileMedia {
      width: calc(100vw - 3.2rem);
    }
  }

  &__info {
    position: relative;
    border-top-left-radius: var(--border-radius-small);
    border-bottom-left-radius: var(--border-radius-small);
    @include mobileMedia {
      border-radius: 0 0 var(--border-radius-small) var(--border-radius-small);
      
      height: auto;
    }
  }

  &__media {
    position: relative;
    border-top-right-radius: var(--border-radius-small);
    border-bottom-right-radius: var(--border-radius-small);
    @include mobileMedia {
      height: 19.2rem;
      border-radius: var(--border-radius-small) var(--border-radius-small) 0 0 ;
    }
  }

  &__info-slide {
    position: relative;
    height: 100%;
    background: $color-blue-6;
    padding: 5.4rem;
    @include mobileMedia {
      padding: 2.4rem 1.6rem;
      min-height: 30rem;
    }
  }
  
  &__slide-title {
    @include subtitle;
    color: white;
    margin-bottom: 3.2rem;
    letter-spacing: 0.1rem;
    @include mobileMedia {
      margin-bottom: 1.6rem;
    }
  }
  
  &__slide-text {
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    
    color: $color-text-light;

    @include mobileMedia {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.67;
    }
  }
  
  &__slide-list {
    padding-left: 1.6rem;
  }

  &__slide-listItem {
    list-style-image: url("../../../img/check-list.svg");
    padding-left: 0.5rem;
    letter-spacing: 0.02rem;
  }
  
  &__media-slide {
    position: relative;
    height: 100%;
  }
  
  &__media-img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    @include mobileMedia {
      
    }
  }
  
  & .swiper-container {
    height: 100%;

    @include mobileMedia {
      overflow-x: hidden;
      overflow-y: visible;
    }
    .partnership-slider__pagination{
      position: absolute;
      z-index: 2;
      margin-left: 5.4rem;
      margin-bottom: 5.2rem;

      @include mobileMedia {
        position: static;
        margin: 2.4rem auto 5.6rem;
        display: flex;
        justify-content: center;

      }

      .swiper-pagination-bullet{
         width: 1.2rem;
         height: 1.2rem;
         margin-right: 1.6rem;
         margin-left: 0;
         background-color: $color-blue-11;
         opacity: 1;
          position: relative;
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 50%;
          background-image: linear-gradient(to right, #0e78db, #015cf5);
          transform: scale(0);
          transition: 0.3s transform ease-in-out;
        }

         @include mobileMedia {
          width: 0.8em;
          height: 0.8rem;
          margin-right: 1.2rem;
         }

         &.swiper-pagination-bullet-active{
            &:after {
              transform: scale(1);
            }
         }
      }
    }
  }
  
  
   
}

