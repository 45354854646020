@import "../../../../scss/common";

.partner-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.4rem;
  background: $color-blue-4;
  margin-right: 2.4rem;
  width: 19.2rem;
  height: auto;
  padding: 2.6rem 1.6rem 1.6rem;
  background:  url("../../../../img/partner-item/partner-item-body-bs.svg") no-repeat;
  background-size: cover;
  margin-bottom: 3.2rem;

  @include mobileMedia {
    width: 11.2rem;
    height: 12rem;
    margin-right: 1.6rem;
    padding: 0.8rem;
    // TODO add carusel
    flex-shrink: 0;
    margin-bottom: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  
  &_game_keno {
    background-image:  url("../../../../img/partner-item/partner-item-body-keno.svg");
  }
  &_game_ler, &_game_ar, &_game_bj, &_game-cr, &_game_bc  {
    background-image:  url("../../../../img/partner-item/partner-item-body-ler.svg");

    @include mobileMedia {
      background-image:  url("../../../../img/partner-item/partner-item-body-ler-mobile.svg");
    }
  }

  &__logo {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 11.2rem;
    height: 6.4rem;
    border-radius: 0.4rem;
    @include center;

    @include mobileMedia {
      width: 8rem;
      height: 4rem;
      padding: 1.5rem 1rem;
    }
    
    &_game {

      &_keno {
        background: $color-dark-keno;
      }
  
      &_ler, &_ar, &, &-cr, &_bc {
        background: $color-gray;
      }
    }
  }
  
  &__img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0.4rem;
  }
  
  &__title {
    margin-top: 5.6rem;
    margin-bottom: 0.8rem;
    font-family: Gilroy,Arial,sans-serif;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;

    @include mobileMedia {
      margin-top: 4.2rem;
      font-family: Gilroy;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.57;
    }
    &_game {
  
      &_keno {
        color: $color-text-light-keno;
      }
  
      &_ler, &_ar, &_bj, &-cr, &_bc {
        color: white;
      }
    }
  }
  
  &__date {
    opacity: 0.8;
    font-family: Lato, Arial, sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.33;
    text-align: center;
    color: $color-text-light;

    &_game {
    
      &_keno {
        color: $color-text-light-keno;
      }
    
      &_ler, &_ar, &_bj, &-cr, &_bc {
        color: white;
      }
  }
  }
  
  &__btn {
    width: 16rem;
    height: 4.8rem;
    margin-top: 2.4rem;

    @include mobileMedia {
      width: 100%;
      height: 3.2rem;
    }
  }
}
