@import "../../../scss/common";

.news-section-list {
  margin-bottom: 23.2rem;

  @include mobileMedia {
    margin-bottom: 8rem;
  }
  &__inner {
    @include container;
  }

  &__sceleton-item {
    border-radius: 4px;
    background-color: #131a60;
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 47.2rem;
    grid-gap: 2.4rem;

    @include mobileMedia {
      grid-template-columns: 1fr;
      grid-gap: 1.6rem;
      grid-auto-rows: auto;
    }
  }

  &__button {
    height: 4.8rem;
  }
}
