@import "../../../scss/common";
.game-devices {
  
  position: relative;

  @include mobileMedia {
    margin-bottom: 10rem;
    margin-top: 8rem;
  }
  
  @include  desktopMedia {
    &_game {
      &_keno {
        &:after {
          content: '';
          display: block;
          width: 55.1rem;
          height: 59.2rem;
          position: absolute;
          left: 20rem;
          top: 5rem;
          background: url("../../../img/game-page/keno/decor/blur.png") no-repeat center;
          background-size: auto 100%;
          z-index: -1;
          opacity: 0.55;
        }
      }
  
      &_ler, &_ar, &_bj, &-cr, &_bc{
        &:after {
          content: '';
          display: block;
          position: absolute;
          right: 13.9rem;
          top: 12rem;
          width: 42.6rem;
          height: 45.7rem;
          background: url("../../../img/game-page/ler/decor/blur.png") no-repeat;
          background-size: auto 100%;
          z-index: -1;
          opacity: 0.1;
        }
      }
    }
    
  }
  
  &__container {
    @include container;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    @include subtitle;
    color: white;
    text-align: center;
    bottom: 3.2rem;
    margin-bottom: 3.2rem;
    @include mobileMedia {
      margin-bottom: 2.4rem;
    }
  }
  
  &__select {
    height: 7.2rem;
    background: rgba($color-blue-4, 0.4);
    display: flex;
    margin-bottom: 6.4rem;
    
    &_game {
      &_keno {
        background: rgba(#130043, 0.5);
      }
  
      &_ler, &_ar, &_bj, &-cr, &_bc {
        background: rgba($color-gray, 0.6);
      }
    }

    @include mobileMedia {
      margin-bottom: 2.4rem;
    }
  }
  
  &__select-item {
    position: relative;
    width: 7.2rem;
    height: 7.2rem;
    border-radius: 0.4rem;
    overflow: hidden;
    @include center;
    cursor: pointer;
    transition: 0.3s background ease-in-out;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 6rem;
      bottom: 0;
      background: url("../../../img/select-shadow.svg") no-repeat bottom;
      opacity: 0;
      transition: 0.3s opacity ease-in-out;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.2rem;
      background-image: linear-gradient(to right, #0e78db, #015cf5);
      opacity: 0;
      transition: 0.3s opacity ease-in-out;
    }
    &_active {
      background-color: $color-blue-4;
      &:after {
        opacity: 0.3;
      }
    }
    
    &_active {
      &:before {
        opacity: 1;
      }
    }
  
    &_game {
      &_keno {
        &:after {
          background: url("../../../img/game-page/keno/select-shadow.svg") no-repeat bottom;
        }
  
        &:before {
          background: #5f05d6;
        }
        &.game-devices__select-item_active {
          background-color: #190056;
        }
      }
    
      &_ler, &_ar {
        &:after {
          background: url("../../../img/game-page/ler/select-shadow.svg") no-repeat bottom;
        }
        
        &:before {
          background: #ffd000;
        }
        &.game-devices__select-item_active {
          background-color: #151617;
        }
      }

      &_bj {
        
        &:before {
          background: linear-gradient(90deg, #DCB46D 0%, #C4A163 42.19%, #946F2F 100%);
        }
        &.game-devices__select-item_active {
          background-color: #151617;
        }
      }

      &-cr {
        &:before {
          background: linear-gradient(180deg, #03E9EB 0%, #0A869D 100%);
        }
        &.game-devices__select-item_active {
          background-color: #151617;
        }
      }
      &_bc {
        &:before {
          background: linear-gradient(90deg, #FFC164 0%, #E58900 42.19%, #7F4C00 100%);
        }
        &.game-devices__select-item_active {
          background-color: #151617;
        }
      }
    
    }
  }
  
  &__select-item-img {
    height: 5.6rem;
    width: 5.6rem;
    opacity: 0.8;
    transition: 0.3s opacity ease-in-out;
  
    .game-devices__select-item_active &, .game-devices__select-item:hover &  {
      opacity: 1;
    }
  }

  &__images {
    position: relative;
    width: 100.8rem;
    height: 56.7rem;

    @include mobileMedia {
      width: 100vw;
      height: 56.25vw;
    }

    &:before, &:after{
      content: "";
      position: absolute;
      z-index: 1;
    }

    &-pseudo {
      position: absolute;
      z-index: 1;

      &_game_ar, &_game_bj {
        &:nth-child(1) {
          bottom: 20.6rem;
          left: 15.5rem;
          width: 8rem;
          height: 9rem;
          background: url("../../../img/game-page/ler/decor/coin-3.png") no-repeat center center / cover; 
          
          @include mobileMedia {
            bottom: 8rem;
            left: 6.2rem;
            width: 3rem; 
            height: 3rem
          }
        }

        &:nth-child(2) {
          bottom: 14.6rem;
          left: 6.2rem;
          width: 25rem;
          height: 20rem;
          background: url("../../../img/game-page/ler/decor/blur3.png") no-repeat center center / cover; 
          
          @include mobileMedia {
            bottom: 2.4rem;
            left: 0.4rem;
            width: 13rem; 
            height: 13rem
          }
        }
      }

      &_game-cr {
        &:nth-child(1) {
          content: '';
          width: 127px;
          height: 127px;
          bottom: 15rem;
          left: 12.5rem;
          background-color: #FF618A;
          filter: blur(150px);

          @include mobileMedia {
            bottom: 7.5rem;
            left: 5.4rem;
            width: 4rem;
            height: 4rem;
            filter: blur(35px);
          }
        }
      }
    }
  }

  &__img-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    &_active {
      opacity: 1;
      visibility: visible;
    }
  }
}
