@import "../../../../scss/common";

.game-devices-slider {
  position:fixed;
  top: 0;
  left: 0;
  height: calc(var(--vh) * 100);
  width: 100vw;
  z-index: 10;
  @include center;

  
  
  &__arrow {
    width: 4.8rem;
    height: 4.8rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    @include mobileMedia {
      position: fixed;
      width: 3.2rem;
      height: 3.2rem;
      top: unset;
      bottom: 1.6rem;
      transform: translateY(0%);

    }

    @media (orientation: landscape) {
      top: 50%;
      bottom: unset;
      transform: translateY(-50%);
    } 
    &_prew {
      left: 4.8rem;
      
      @include mobileMedia {
        left: 1.6rem;
      }
    }
  
    &_next {
      right: 4.8rem;

      @include mobileMedia {
        right: 1.6rem;
      }
    }
  }
  
  &__container {
    width: 100%;
    height: 61.6rem;
    margin-bottom: 7rem;

    @include mobileMedia {
      height: 100%;
      margin-bottom: 0;
    }

    .swiper-container {
      overflow: visible;
      height: 100%;
    }
  }
  
  &__slide {
    padding: 0 calc((100vw - 109.5rem)/2);
    height: 100%;
    @include center
  }
  
  &__img {
    max-height: 100%;
  }
  
  &__pagination {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    bottom:  -5rem !important;

    @include mobileMedia {
      position: fixed;
      bottom: 28px !important;
      z-index: 1;
    }
  }
  
  &__pagination-bullet {
    position: relative;
    z-index: 11;
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 1.6rem 0 0;
    border-radius: 50%;
    background-color: rgba($color-blue-4, 0.5);
    cursor: pointer;

    @include mobileMedia {
      width: 0.8rem;
      height: 0.8rem;
    }
    &:after {
      content: '';
      position: relative;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background-image: linear-gradient(to right, #0e78db, #015cf5);
      transform: scale(0);
      transition: 0.3s transform ease-in-out;

      @include mobileMedia {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
    &_active {
      pointer-events: none;
      &:after {
        transform: scale(1);
      }
    }
    
  }
  
  &_game {
    &_keno {
      .game-devices-slider__pagination-bullet {
        background: rgba(#330286, 0.3);
        &:after {
          background: #330286;
        }
      }
    }
  
    &_ler {
      .game-devices-slider__pagination-bullet {
        background:  #151617;
        &:after {
          background: #ffbf00;
        }
      }
    }

    &_ar {
      .game-devices-slider__pagination-bullet {
        background:  #151617;
        &:after {
          background: linear-gradient(90deg, #FFD000 -14.79%, #FFBF00 120.89%);
        }
      }
    }

    &_bj {
      .game-devices-slider__pagination-bullet {
        background:  #151617;
        &:after {
          background: linear-gradient(90deg, #DCB46D 0%, #C4A163 42.19%, #946F2F 100%);
        }
      }
    }

    &-cr {
      .game-devices-slider__pagination-bullet {
        background:  #151617;
        &:after {
          background: linear-gradient(180deg, #03E9EB 0%, #0A869D 100%);
        }
      }
    }

    &_bc {
      .game-devices-slider__pagination-bullet {
        background:  #151617;
        &:after {
          background: linear-gradient(270deg, #7F4C00 -32%, #E58900 100%);
        }
      }
    }
  }
}
