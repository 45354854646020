@import "../../../scss/common";

.news-section-top {
  min-height: 68.8rem;
  max-height: calc(var(--vh) * 100);
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  @include mobileMedia {
    min-height: 45.2rem;
    align-items: flex-end;
    margin-top: -8rem;
    margin-bottom: 8rem;
  }

  &__banner {
    position: absolute;
    height: calc(100% - 8rem);
    z-index: 1;
    bottom: 0;
    width: 127.2rem;
    left: 50%;
    transform: translate(-50%);
    border-radius: 4px;
    overflow: hidden;
    background-size: cover;
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: white;
      opacity: 0;
      z-index: 2;
      border-radius: 4px;
    }


    @include mobileMedia {
      width: 100%;
      height: 38.4rem;
      top: 0;
      mask: linear-gradient(to bottom, black, black 50%, transparent 100%) no-repeat;

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 18.4rem;
        @include bg-gradient-main;
        z-index: 10; 
      }
    }
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition: 0.3s transform ease-in-out;
  }
  
  &__container {
    @include container;
    padding: 8rem 15.5rem 0;
  }
  
  &__content {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    width: 50%;
    justify-content: center;
    flex-direction: column;

    @include mobileMedia {
      width: 100%;
    }
  }
  
  &__title {
    @include title-5;
    color: white;
    margin-bottom: 2.4rem;
    @include mobileMedia {
      margin-bottom: 1.6rem;
      font-family: Gilroy, Arial, sans-serif;
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 1.25;
      letter-spacing: 2px;
    }
  }
  
  &__text {
    font-family: Lato, Arial, sans-serif;
    font-size: 2.4rem;
    line-height: 1.33;
    letter-spacing: 0.2px;
    margin-bottom: 5.6rem;
    color: white;

    @include mobileMedia {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0.2px;
      margin-bottom: 3.2rem;
      margin-right: 1rem;
    }
  }
  
  &__btns {
    display: flex;
  }
  
  &__btn {
    height: 5.6rem;


    &_demo {
      width: 14.9rem;
      height: 5.6rem;
      margin-right: 2.4rem;

      @include mobileMedia {
        height: 4.8rem;
        width: 100%;
        margin-right: 0;
        
      }
      
    }
    
    &_back {
      width: 5.6rem;
    }
  }
}