@import "../../../scss/common";

.contacts {

  margin: 14.4rem 0;

  @include mobileMedia {
    margin: 3.2rem 0 0;
  }

  &__container {
    @include container;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 2.4rem;

    @include mobileMedia {
      display: block;
    }
  }
  
  &__part {
    &_left {
      grid-area: 1/1/2/4;

      @include mobileMedia {
        margin-bottom: 5.6rem;
      }
    
    }
  
    &_right {
      grid-area: 1/4/2/7;
      @include mobileMedia {
        margin-bottom: 8rem;
      }
    }
  }
  
  &__title {
    @include title-5;
    color: white;
    margin-bottom: 4.8rem;
    line-height: 1.17;
    letter-spacing: 0.5rem;

    @include mobileMedia {
      margin-bottom: 2.4rem;
    }
  }
  
  &__items {
    margin-bottom: 7.2rem;
    @include mobileMedia {
      margin-bottom: 4rem;
    }
  }
  
  &__item {
    margin-bottom: 3.2rem;

    @include mobileMedia {
      margin-bottom: 1.6rem;
    }
  
  }
  
  &__item-link {
    display: inline-block;
    font-family: Gilroy, Arial, sans-serif;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: #ffffff;
    margin-bottom: 0.8rem;

    &:hover, &:focus {
      @media (pointer: fine) {
        color: $color-blue-10;
      }
    //   @include bg-gradient-light;
    //   background-clip: text;
    //   -webkit-text-fill-color: transparent;
    }
  }
  
  &__item-info {
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
    color: $color-text-light;
  }
  
  &__form {
    width: 51.6rem;

    @include mobileMedia {
      width: 100%;
    }
  }
}

