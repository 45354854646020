@import "../../../../scss/common";
.game-devices-img {
  @include desktopMedia {
    &_game {
      &_keno {
        cursor:  url("../../../../img/game-page/keno/cursor.png"), pointer;
      }
    
      &_ler, &_ar, &_bj {
        cursor:  url("../../../../img/game-page/ler/cursor.png"), pointer;
      }

      &-cr {
        cursor: url("../../../../img/game-page/CR/cursor.svg"), pointer;
      }

      &_bc {
        cursor:  url("../../../../img/game-page/Baccarat/cursor.png"), pointer;
      }
    }
  }

  @include mobileMedia {
    margin-bottom: 4.6rem;
  }
  
  &__modal {
    height: calc(var(--vh) * 100);
    width: 100vw;
    &_game {
  
      &_keno {
        background: $color-dark-keno2;
      }
  
      &_ler, &_ar, &_bj, &-cr {
        background: black;
      }
    }
  }
  
  &__modal-close {
    position: absolute;
    top: 4.1rem;
    right: 4.8rem;
    width: 4.8rem;
    height: 4.8rem;
    z-index: 11;

    @include mobileMedia {
      top: 1.6rem;
      right: unset;
      left: calc( 100vw - 1.6rem);
      transform: translateX(-100%);
      width: 3.2rem;
      height: 3.2rem;
    }
  }

  &__fullscreen-btn {
    height: 3.2rem;
    width: fit-content;
    min-width: 13.5rem;
    margin: 0 auto;
  }
}
