@import '../../../scss/common';

.input-field {
  position: relative;
  display: block;
  @include field;
  overflow: hidden;
  width: 100%;

  &.input-field_textarea {
    height: 11.2rem;

    @include mobileMedia {
      height: 9.6rem;
    }
  }



  &__input {
    @include input;
    padding-left: 5.6rem;
    font-family: Lato, Arial, sans-serif;

    .input-field_alt & {
      padding-left: 1.6rem;

    }
    //&:focus  ~ {
    //  .input-field__icon {
    //    transform: translateX(0);
    //  }
    //
    //  .input-field__label span {
    //    transform: translateX(100px);
    //    opacity: 0;
    //  }
    //}

    .input-field_textarea & {
      resize: none;
      padding: 1.6rem 5.6rem 0;
      align-items: flex-start;
    }
  }

  &__label {
    position: absolute;
    display: flex;
    align-items: center;
    padding-left: 1.6rem;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    font-family: 'Lato', PTRootUI, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal;
    color: $color-text-light;
    opacity: 1;
    visibility: visible;
    cursor: pointer;

    @include mobileMedia {
      font-size: 1.4rem;
    }

    transition: 0.4s transform ease-out, 0.2s opacity ease-in-out 0.2s;

    .input-field_textarea & {
      align-items: flex-start;
      padding-top: 1.6rem;
    }

    .input-field__focused & {
      transform: translateX(100%);
      opacity: 0;
      transition: 0.4s transform ease-in, 0.2s opacity ease-in-out;
    }

    .input-field__filled & {
      transform: translateX(100%);
      opacity: 0;
      transition: 0s transform, 0s opacity;
    }
  }

  &__icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 5.6rem;
    height: 5.6rem;
    @include center;
    transition: 0.3s transform ease-in-out;
    transform: translateX(-100%);

    @include mobileMedia {
      width: 4.8rem;
      height: 4.8rem;
    }

    &__focused {
      transform: translateX(0);
    }

    img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &__status {
    position: absolute;
    top: 50%;
    right: 1.6rem;
    transform: translateY(-50%);
  }

  .input-field_textarea_counter {
    display: block;
    position: absolute;
    bottom: 0;
    right: 1.6rem;
    transform: translateY(-50%);
    opacity: 0.5;
    font-family: Lato;
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: $color-text-light;
  }

}

.jobMessage {
  top: 3rem;
}
