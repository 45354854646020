@import "../../../scss/common";

.games-list {
  background: $color-main;
  padding-bottom: 23.2rem;

  @include mobileMedia {
    padding-bottom: 8rem;
  }
  &__inner {
    @include container;
  }
  
  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 44rem;
    grid-gap: 2.4rem;

    @include mobileMedia {
      grid-template-columns: 1fr;
      grid-auto-rows: 23.2rem;
      grid-gap: 1.6rem;
    }
  }
  
  &__item {
    border-radius: 0.4rem;
    overflow: hidden;
  }
}