@import "../../../scss/common";
/* Component */
.progress-button {
  --progressTime: 0.6s;
  // --progressDelay: 0.3s;
  --progressBtnSize: 5.6rem;

  @include mobileMedia {
    --progressBtnSize: 4.8rem;
    width: 100%;
  }

  height: var(--progressBtnSize);
  width: 40.8rem;
  overflow: hidden;
  border-radius: 0.4rem;
  position: relative;

  .btn-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    @include bg-gradient;
    border: none;
    outline: none;

    color: white;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    font-family: Gilroy, Arial, sans-serif;
    font-size: 1.4rem;
    font-weight: 800;
    line-height: 1.71;
    letter-spacing: 0.1rem;
    &:disabled {
      opacity: 0.5;
      @include bg-gradient-dark;
      cursor: not-allowed;
    }
  }

  &.error,
  &.loading,
  &.success {
    .btn-loading {
      cursor: not-allowed;
      span {
        opacity: 0;
      }
    }
  }

  .loader,
  .success,
  .error {
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    transition: all 0.25s ease-in;
    animation: backOutRight 0.5s cubic-bezier(0.79, 0.14, 0.32, 0.93) forwards;
    transform-origin: center center;
    cursor: not-allowed;
    pointer-events: none;
    z-index: 1050;
  }
  .loader::before {
    content: " ";
    width: 2.4rem;
    height: 2.4rem;
    border: 4px solid #fff;
    border-radius: 50%;
    border-color: #fff #ffffff4d #ffffff4d #ffffff4d;
  }

  &.error {
    .error {
      background: #ff335a;
      display: flex;
      animation: none;
      svg {
        animation: shakeX 1.2s cubic-bezier(0.79, 0.14, 0.32, 0.93) forwards;
      }
    }
  }
  &.loading {
    .loader {
      background: transparent;
      display: flex;
      animation: none;
      &::before {
        animation: loading 1.2s cubic-bezier(0.79, 0.14, 0.32, 0.93) infinite;
      }
    }
  }
  &.success {
    .success {
      background: #5be587;
      display: flex;
      animation: none;
      svg {
        animation: fadeIn 1.2s cubic-bezier(0.79, 0.14, 0.32, 0.93) forwards;
      }
    }
  }
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes shakeX {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes backOutRight {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  20% {
    transform: translateX(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7;
  }
}
