@import '../../../scss/common';

.error {
  display: flex;
  margin-bottom: 1.6rem;
  animation-duration: 0.3s;
  animation-name: errorIn;
  overflow: hidden;
  transition: height 0.3s;

  &:first-child {
    margin-top: 3.2rem;
  }

  &_msg {
    font-family: Lato;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $color-error;
    margin-left: 1.6rem;

    @include mobileMedia {
      font-size: 1.4rem;
    }
  }
}
