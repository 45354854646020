@keyframes scale-easeInElastic {
  0% {
    transform: scale(0); }
  25% {
    transform: scale(0.8); }
  50% {
    transform: scale(1.1); }
  75% {
    transform: scale(1.3); }
  100% {
    transform: scale(1); } }

@font-face {
  font-family: 'Lato';
  src: url("../../fonts/Lato/Lato-Italic.woff2") format("woff2"), url("../../fonts/Lato/Lato-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Lato';
  src: url("../../fonts/Lato/Lato-Black.woff2") format("woff2"), url("../../fonts/Lato/Lato-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Lato';
  src: url("../../fonts/Lato/Lato-Thin.woff2") format("woff2"), url("../../fonts/Lato/Lato-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Lato';
  src: url("../../fonts/Lato/Lato-Bold.woff2") format("woff2"), url("../../fonts/Lato/Lato-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Lato';
  src: url("../../fonts/Lato/Lato-Regular.woff2") format("woff2"), url("../../fonts/Lato/Lato-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Lato';
  src: url("../../fonts/Lato/Lato-Medium.woff2") format("woff2"), url("../../fonts/Lato/Lato-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("../../fonts/Gilroy/Gilroy-Regular.woff2") format("woff2"), url("../../fonts/Gilroy/Gilroy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy';
  src: url("../../fonts/Gilroy/Gilroy-Medium.woff2") format("woff2"), url("../../fonts/Gilroy/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy';
  src: url("../../fonts/Gilroy/Gilroy-Bold.woff2") format("woff2"), url("../../fonts/Gilroy/Gilroy-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'PT Root UI';
  src: url("../../fonts/PTRootUI/PTRootUI-Bold.woff2") format("woff2"), url("../../fonts/PTRootUI/PTRootUI-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'PT Root UI';
  src: url("../../fonts/PTRootUI/PTRootUI-Light.woff2") format("woff2"), url("../../fonts/PTRootUI/PTRootUI-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'PT Root UI';
  src: url("../../fonts/PTRootUI/PTRootUI-Medium.woff2") format("woff2"), url("../../fonts/PTRootUI/PTRootUI-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'PT Root UI';
  src: url("../../fonts/PTRootUI/PTRootUI-Regular.woff2") format("woff2"), url("../../fonts/PTRootUI/PTRootUI-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("../../fonts/Gilroy/Gilroy-SemiBold.woff2") format("woff2"), url("../../fonts/Gilroy/Gilroy-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

.page {
  background: #04053c; }
  .page__container {
    min-height: calc(var(--vh) * 100);
    display: flex;
    flex-direction: column; }
  .page__main-container {
    margin-bottom: 23.2rem; }

:root {
  --scrollBarVisibility: 0; }

html,
body {
  height: 100%;
  min-height: 100%;
  background: linear-gradient(#04053c, #04053c); }
  @media (max-width: 480px), (max-width: 812px) and (max-height: 520px) and (orientation: landscape) {
    html,
    body {
      background: #010728; } }

body::-webkit-scrollbar {
  width: var(--scrollBarVisibility); }

* {
  scroll-behavior: smooth; }

.d-none {
  display: none; }

.view-height {
  height: 100%;
  min-height: 100%; }

html {
  font-size: 10px;
  scroll-behavior: smooth; }
  @media (max-width: 1336px) {
    html {
      font-size: 8px; } }
  @media (max-width: 1120px) {
    html {
      font-size: 7px; } }
  @media (max-width: 480px), (max-width: 812px) and (max-height: 520px) and (orientation: landscape) {
    html {
      font-size: 10px; } }
  @media (max-width) {
    html {
      font-size: 9px; } }

body {
  height: 100%;
  min-height: calc(var(--vh) * 100);
  margin: 0;
  font-family: Lato, Arial, sans-serif;
  overflow-x: hidden; }
  body * {
    box-sizing: border-box; }

[tabindex='-1']:focus {
  outline: none !important; }

[role='button'] {
  cursor: pointer; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

a,
button,
[role='button'],
input,
label,
select,
textarea {
  touch-action: manipulation;
  margin: 0; }

button,
input {
  overflow: visible;
  text-transform: none; }

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

svg {
  fill: currentColor; }
  a svg,
  button svg {
    pointer-events: none; }
  svg:not(:root) {
    overflow: hidden; }

img {
  max-width: 100%;
  height: auto; }

a {
  color: inherit;
  background-color: transparent;
  text-decoration: none; }
  @media (pointer: fine) {
    a:hover, a:focus {
      color: inherit; } }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: 0;
  border-top: 1px solid inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0; }

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0; }

p {
  margin: 0;
  padding: 0; }

address {
  font-style: normal; }

ul,
ol {
  margin-bottom: 0;
  padding-left: 1.2em; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-top: 0;
    margin-bottom: 0; }

li {
  line-height: inherit; }

blockquote {
  padding: 0;
  margin-left: 0;
  margin-right: 0; }

.big {
  font-size: 2rem; }

button {
  padding: 0;
  outline: none;
  background: inherit;
  font-family: inherit;
  border: none;
  box-shadow: none;
  cursor: pointer; }

button:hover,
button:focus,
button:active {
  border: none;
  outline: none;
  box-shadow: none; }

.part__title-container {
  width: 100%;
  max-width: 136.6rem;
  margin: 0 auto;
  padding: 0 4.7rem;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (max-width: 480px), (max-width: 812px) and (max-height: 520px) and (orientation: landscape) {
    .part__title-container {
      padding: 0 1.6rem; } }
  @media (max-width: 480px), (max-width: 812px) and (max-height: 520px) and (orientation: landscape) {
    .part__title-container {
      flex-direction: row;
      margin-bottom: 1.6rem; } }
  .part__title-container .part__title {
    font-family: Gilroy, Arial, sans-serif;
    font-size: 4.8rem;
    font-weight: bold;
    letter-spacing: 0.24rem;
    text-transform: uppercase;
    color: white;
    margin-bottom: 0.5rem;
    text-transform: uppercase; }
    @media (max-width: 480px), (max-width: 812px) and (max-height: 520px) and (orientation: landscape) {
      .part__title-container .part__title {
        font-size: 3.2rem;
        line-height: 1.25;
        letter-spacing: 0.2rem; } }
    @media (max-width: 480px), (max-width: 812px) and (max-height: 520px) and (orientation: landscape) {
      .part__title-container .part__title {
        margin-bottom: 0;
        margin-right: auto; } }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset; }

.page__section {
  margin: 14.4rem 0; }

@media (max-width: 480px), (max-width: 812px) and (max-height: 520px) and (orientation: landscape) {
  .page {
    padding-top: 8rem;
    overflow: hidden; }
    .page.news-page, .page.game-page {
      padding-top: 0; } }
