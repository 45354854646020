@import "../../scss/common";

.game-bottom.game-bottom_game_bc::after {
  content: '';
  position: absolute;
  bottom: 150px;
  right: 0;
  width: 164px;
  height: 164px;
  background: #ee9342;
  border-radius: 50%;
  filter: blur(250px);
  -webkit-filter: blur(150px);
  z-index: 2;
}

.baccarat-rules {
  img {
    display: block;
    margin: 2.4rem auto;
    @include mobileMedia {
      margin: 1.6rem auto;
      width: 100%;
    }
  }

  &__img-fixed {
    img {
      @include mobileMedia {
        width: auto;
      }
    }
  }
  ul, ol {
    margin-bottom: 5rem;
    opacity: 0.9;
  }
  &__row-with-img {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-bottom: 1.6rem;
    img {
      margin: 0;
      width: auto;
    }
  }
  &__two-column-table{
    width:100%;
    border-collapse: collapse;
    tr {
      &:first-child {
        font-family: Gilroy;
        th {
          opacity: 1;
        }
      }
    }

    th {
      text-align: left;
      font-weight: 500;
      border-bottom: 1px solid #303030;
      padding: 0.8rem 0;
      width: 50%;
      opacity: 0.7;
      &:first-child {
        padding-left: 0.8rem;
      }
  }

  }
  &__big-table {
    overflow: auto;
    table {
      border-collapse: collapse;
      width: 100%;
    }
    th {
      padding: 10px 12px;
      border: 1px solid #303030;
      font-family: Lato;
      font-weight: 400;
    }

    tr:nth-child(-n+2) th {
      font-weight: 600;
      font-family: Gilroy;
    }

    th:first-child {
      font-weight: 600;
      font-family: Gilroy;
    }
    td {
      border: 1px solid #303030;
      padding: 10px 12px;
      text-align: center;
    }
  }
}