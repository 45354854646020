@import "../../../scss/common";
.games-header {
  padding-top: 15.2rem;

  @include mobileMedia {
    padding-top: 3.2rem;
  }
  &__contain {
    @include container;
  }
  
  &__title {
    @include title-2;
    text-align: center;
    margin-bottom: 4rem;

    @include mobileMedia {
      text-align: start;
      margin-bottom: 2.4rem;
    }
  }

  &__separator {
    @include mobileMedia {
      display: block;
    }
  }
}