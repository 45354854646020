@import "../../../scss/common";

.game-top {
  height: calc(var(--vh) * 100);
  transition: 0.1s height linear;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  @include mobileMedia {
    height: auto;
    margin-bottom: 8rem;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__img {
    position: absolute;
    transition: 0.3s height cubic-bezier(0.25, 1, 0.5, 1);
    height: 100%;
    width: 100%;
    z-index: 1;
    object-fit: cover;
    mask-image: linear-gradient(to top, transparent, black 19%, black);

    @include mobileMedia {
      position: static;
      height: 45.2rem;
      object-position: right center;
      top: 0;
      mask-image: linear-gradient(
        to top,
        transparent,
        transparent 10%,
        black 25%,
        black
      );

      &_game {
        &_keno {
          object-position: calc(50% - 13rem) -2.4rem;
        }
        &_ar {
          content: url("../../../img/game-page/Ar/banner-mobile.jpg");
          object-fit: contain;
          margin-bottom: 7rem;
          object-position: 0 -0.5rem;
        }

        &_bj,
        &-cr,
        &_bc{
          height: auto;
          mask-image: linear-gradient(
            to top,
            transparent,
            transparent 3%,
            black 25%,
            black
          );
          margin-bottom: 10rem;
        }
      }
    }
  }

  &__container {
    @include container;
    @include mobileMedia {
      position: absolute;
      bottom: -1rem;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 10.2rem;

    @include mobileMedia {
      position: static;
      width: 100%;
      padding: 0;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }

  &__title {
    @include title-5;
    color: white;
    margin-bottom: 2.4rem;
    &__game_bc{
      font-family: Gilroy, serif;
      font-size: 32px;
      font-weight: 700;
    }
    @include mobileMedia {
      margin-bottom: 1.6rem;
      &__game_bc{
        display: flex;
        flex-wrap: wrap;
        width: 100px;
      }
    }

  }

  &__text {
    opacity: 0.9;
    font-family: Lato, "PT Root UI";
    font-size: 2.4rem;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 5.6rem;
    padding-right: 4.8rem;

    @include mobileMedia {
      font-family: Lato, "PT Root UI";
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.02rem;
      margin-bottom: 0;
      padding: 0;
    }
  }

  &__btns {
    display: flex;

    @include mobileMedia {
      width: 100%;
    }
  }

  &__btn {
    height: 5.6rem;

    &_demo {
      width: 14.9rem;
      height: 5.6rem;
      margin-right: 2.4rem;

      @include mobileMedia {
        height: 4.8rem;
        width: 100%;
        margin-right: 0;
      }
    }

    &_back {
      width: 5.6rem;
      margin-bottom: 5.6rem;

      @include mobileMedia {
        position: fixed;
        z-index: 3;
        top: 1.6rem;
        right: 1.6rem;
        height: 3.2rem;
        width: 3.2rem;
        pointer-events: all;
      }
    }
  }

  &__demo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15.1rem;
    height: 5.6rem;
    border-radius: 4px;
    font-family: "Gilroy", sans-serif;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.7;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: white;

    &:hover {
      color: white;
    }

    @include mobileMedia {
      width: 100%;
      margin-top: 15px;
    }
  }
}
