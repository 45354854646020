@import '../../../scss/common';

.game-bottom {
  position: relative;
  margin-bottom: 23.2rem;

  &_game {
    @include mobileMedia {
        &_ar, &_bj {
          &:before {
            content: "";
            position: absolute;
            width: 3.5rem;
            height: 3.5rem;
            top: -4rem;
            left: 29rem;
            background:  url('../../../img/game-page/ler/decor/coin-2.png') no-repeat;
            background-size: auto 66%;
          }

          &:after {
            content: "";
            position: absolute;
            width: 5rem;
            height: 5rem;
            top: -6rem;
            left: 31.5rem;
            background:  url('../../../img/game-page/ler/decor/coin-1.png') no-repeat;
            background-size: auto 66%;
          }
        }
    }
  }

  &__decor {
    display: block;
    position: absolute;
    z-index: 1;

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
    }

    &_game {
      &_keno {
        bottom: -25rem;
        right: -8.5rem;
        width: 34.7rem;
        height: 37.3rem;
        &:before {
          width: 8.7rem;
          height: 10.5rem;
          top: 10.7rem;
          left: 15rem;
          background: url('../../../img/game-page/keno/decor/coin-1.svg');
          background-size: cover;
        }
        &:after {
          width: 5.4rem;
          height: 5.3rem;
         bottom: 11.2rem;
          left: 9.3rem;
          background: url('../../../img/game-page/keno/decor/coin-2.svg');
          background-size: cover;
        }
      }
  
      &_ler {
        bottom: -25rem;
        right: -3.7rem;
        width: 30.8rem;
        height: 33.1rem;
        &:before {
          background-size: auto 60%;
          width: 29.9rem;
          height: 16.8rem;
          top: 5.4rem;
          left: 10.4rem;
          background:  url('../../../img/game-page/ler/decor/coin-1.png') no-repeat;
          background-size: auto 60%;
        }
        &:after {
          width: 20rem;
          height: 11.3rem;
          bottom: 7.2rem;
          left: 1.2rem;
          background:  url('../../../img/game-page/ler/decor/coin-2.png') no-repeat;
          background-size: auto 66%;
        }
      }
  
      &_ar, &_bj {
        bottom: -23rem;
        right: 1.7rem;
        width: 30.8rem;
        height: 33.1rem;
      }

      &-cr {
        bottom: 18rem;
        right: 25rem;

        @include mobileMedia {
          right: 16rem;
        }
      }

      &_bc {
        bottom: 18rem;
        right: 25rem;

        @include mobileMedia {
          right: 16rem;
        }
      }

    }

    &-pseudo {
      content: '';
      display: block;
      position: absolute;


      &_game {
        &_ar, &_bj {
          &:nth-child(1) {
            width: 29.9rem;
            height: 16.8rem;
            top: 5.4rem;
            left: 10.4rem;
            background:  url('../../../img/game-page/ler/decor/coin-1.png') no-repeat;
            background-size: auto 60%;

          }

          &:nth-child(3) {
            width: 20rem;
            height: 11.3rem;
            bottom: 7.2rem;
            left: 1.2rem;
            background:  url('../../../img/game-page/ler/decor/coin-2.png') no-repeat;
            background-size: auto 66%;
          }
        }
      }
    }

    @include mobileMedia {
      // display: none;

      &_game {
        &_ar, &_bj, &_bc {
          display: none;
        }
      }
    }
  }

  @include mobileMedia {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &__decor-bg {
    &_game {
      &_keno {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0.55;
        background: url('../../../img/game-page/keno/decor/blur.png') no-repeat;
        background-position: center;
        background-size: cover;
      }
  
      &_ler {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0.25;
        background: url('../../../img/game-page/ler/decor/blur2.png') no-repeat;
        background-position: center;
        background-size: cover;
      }

      &_ar, &_bj {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 20.5rem;
        height: 20.5rem;
        opacity: 0.3;
        background-position: center;
        background: #FFC001;
        filter: blur(160px);
      }

      &-cr {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 274px;
        height: 274px;
        background-position: center;
        background: #71F4FF;
        filter: blur(250px);

        @include mobileMedia {
          width: 164px;
          height: 164px;
          filter: blur(150px);

        }
      }

      &_bc {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 274px;
        height: 274px;
        background-position: center;
        background: #EE9342;
        border-radius: 274px;
        filter: blur(250px);

        @include mobileMedia {
          width: 164px;
          height: 164px;
          filter: blur(150px);

        }
      }
    }
  }

  &__container {
    @include container;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  &__tabs {
    display: flex;
    // position: relative;
    width: 84rem;
    margin-bottom: 9.6rem;

    position: sticky;
    top: 0;
    z-index: 1;

    @include mobileMedia {
      width: 100%;
      padding-top: 1.6rem;
      margin-bottom: 3.2rem;
      
      .game-page_game_keno & {
        background-image: linear-gradient(to right, #0d0319 5%, #0d0230 96%);
      }
           
      .game-page_game_ler, .game-page_game_ar, .game-page_game_br, .game-page_game_bc {
        background: #000000;
      }
      
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 0.4rem;
      width: 100%;
      left: 0;
      bottom: 0;
      background: $color-blue-4;
      border-radius: 0.2rem;
      z-index: 1;
    }
    &_game {
      &_keno {
        &:after {
          background: $color-dark-keno;
        }
      }

      &_ler, &_ar, &_bj, &-cr, &_bc {
        &:after {
          background: $color-gray;
        }
      }

    }
    &.hide_tabs{
      visibility: hidden;
    }
  }

  &__tag {
    display: block;
    position: absolute;
    height: 0.4rem;
    width: calc(100% / 2);
    left: 0;
    bottom: 0;
    background: $color-blue-10;
    border-radius: 0.2rem;
    z-index: 2;
    transition: 0.64s transform cubic-bezier(0.65, 0.05, 0.36, 1);

    &_pos_1 {
      transform: translateX(0);
    }

    &_pos_2 {
      transform: translateX(100%);
    }

    &_pos_3 {
      transform: translateX(200%);
    }

    &_game {
      &_keno {
        background-image: linear-gradient(
          to bottom,
          $color-keno-gradient-1 1%,
          $color-keno-gradient-1
        );
      }

      &_ler, &_ar {
        background-image: linear-gradient(
          to left,
          $color-ler-gradient-1,
          $color-ler-gradient-2 2%
        );
      }

      &_bj {
        background: linear-gradient(90deg, #DCB46D 0%, #C4A163 42.19%, #946F2F 100%);
      }

      &-cr {
        background: linear-gradient(180deg, #03E9EB 0%, #0A869D 100%);
      }
      &_bc {
        background: linear-gradient(270deg, #7F4C00 -50%, #E58900 100%);
      }
    }
  }

  &__tab {
    position: relative;
    font-family: Gilroy, Arial, sans-serif;
    font-size: 2.4rem;
    height: 6.5rem;
    padding-bottom: 1.5rem;
    width: 42rem;
    cursor: pointer;
    @include center;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-align: center;
    color: rgba(white, 0.8);
    text-transform: uppercase;
    transition: 0.3s color ease-in-out;

    @include mobileMedia {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 2.5;
      letter-spacing: 2px;
      width: 50%;
      height: 4rem;
      padding-bottom: 0.4rem;
    }

    &_game {
      &_keno {
        &:after {
          background-image: linear-gradient(
            to bottom,
            $color-keno-gradient-1 1%,
            $color-keno-gradient-1
          );
        }
      }

      &_ler, &_ar, &_bj {
        &:after {
          background-image: linear-gradient(
            to left,
            $color-ler-gradient-1,
            $color-ler-gradient-2 2%
          );
        }
      }

    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 0.4rem;
      width: 100%;
      left: 0;
      bottom: 0;
      background: $color-blue-10;
      border-radius: 0;
      opacity: 0;
      transition: 0.2s opacity ease-in-out;
      z-index: 2;
    }

    &:first-child {
      &:before {
        border-bottom-left-radius: 0.2rem;
        border-top-left-radius: 0.2rem;
      }
    }

    &:last-child {
      &:before {
        border-bottom-right-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
      }
    }

    &_active {
      color: white;
      &:after {
        opacity: 1;
      }
    }

    &:hover:not(&_active) {
      @media (pointer: fine) {
        color: white;
      }
    }
  }

  &__content {
    --scrollIndex: 0;
    mask-image: linear-gradient(to bottom, transparent, transparent var(--scrollIndex), black calc(var(--scrollIndex) + 300px), black 100%);
    @include mobileMedia {
      width: 100%;
    }
  }

  &__features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;

    &_game {
      &-cr {
        z-index: 2;;
      }
    }
  }

  &__feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40.8rem;
    height: 33.6rem;
    margin-right: 2.4rem;
    background: $color-blue-4;
    border-radius: 0.4rem;
    &:last-child {
      margin-right: 0;
    }

    @include mobileMedia {
      width: calc((100vw - 3 * 1.6rem) / 2);
      min-height: calc((100vw - 3 * 1.6rem) / 2);
      height: unset;
      padding-bottom: 0.8rem;
      margin-right: 0;
      margin-bottom: 1.6rem;

      // firt element here is decor // removed 
      &:nth-child(1) {
        margin-right: 1.6rem;
      }

      &:last-child {
        width: 100%;
      }
    }

    &_game {

      &_keno {
        background: $color-dark-keno;
      }

      &_ler, &_ar, &_bj, &-cr, &_bc{
        background: $color-gray;
      }
      &_bc{
        z-index: 3;
      }
    }
  }

  &__feature-icon {
    @include center;
    @include svg;
    
    &_game {

      &_keno {
        &_1 {
          width: 19.6rem;
          height: 17.1rem;
          margin: 3.1rem 0 4.6rem;
          @include mobileMedia {
            width: 7.8rem;
            height: 6.8rem;
            margin-top: 1.2rem;
            margin-bottom: 1.6rem;
            margin-left: 43px;
            margin-right: 35px;
          }
        }

        &_2 {
          width: 17.3rem;
          height: 13.5rem;
          margin: 6.6rem 0 4.7rem;
          @include mobileMedia {
            width: 6.9rem;
            height: 5.3rem;
            margin-top: 2.6rem;
            margin-bottom: 1.7rem;
            margin-left: 47px;
            margin-right: 40px;
          }
        }

        &_3 {
          width: 17rem;
          height: 14.1rem;
          margin: 7.5rem 0 3.2rem;
          @include mobileMedia {
            width: 6.9rem;
            height: 5.7rem;
            margin-top: 3.2rem;
            margin-bottom: 1.5rem;
            margin-left: 127px;
            margin-right: 132px;
          }
        }
      }

      &_ler {
        &_1 {
          width: 16.3rem;
          height: 14.3rem;
          margin: 5.7rem 0 4.8rem;
          @include mobileMedia {
            width: 66px;
            height: 58px;;
            margin-top: 2.6rem;
            margin-bottom: 1.2rem;
          }
        }

        &_3 {
          width: 17.9rem;
          height: 15.6rem;
          margin: 2.7rem 0 6.5rem;
          @include mobileMedia {
            width: 63px;
            height: 51px;
            margin-top: 2.6rem;
            margin-bottom: 1.9rem;
            margin-left: 47px;
            margin-right: 46px;
          }
        }

        &_2 {
          width: 15.4rem;
          height: 12.8rem;
          margin: 5.6rem 0 6.4rem;
          @include mobileMedia {
            width: 72px;
            height: 62px;
            margin-top: 2.3rem;
            margin-bottom: 1.9rem;
            margin-left: 127px;
            margin-right: 129px;
          }
        }
      }

      &_ar {
        &_1 {
          @include mobileMedia {
            width: 80px;
            height: 80px;
            margin: 16px 38px 0;
          }
        }

        &_2 {
          @include mobileMedia {
            width: 80px;
            height: 80px;
            margin: 16px 38px 0;
          }
        }

        &_3 {
          @include mobileMedia {
            width: 80px;
            height: 80px;
            margin: 16px 124px 0;
          }
        }
      }

      &_bj, &-cr, &_bc {
        &_1 {
          width: 25rem;
          height: 15.6rem;
          margin: 4.3rem 0 4.8rem;
          @include mobileMedia {
            width: 80px;
            height: 48px;;
            margin-top: 3rem;
            margin-bottom: 2.6rem;
          }
        }

        &_3 {
          width: 25rem;
          height: 15.6rem;
          margin: 4.5rem 0 4.4rem;
          @include mobileMedia {
            width: 80px;
            height: 51px;
            margin-top: 2.6rem;
            margin-bottom: 1.9rem;
            margin-left: 47px;
            margin-right: 46px;
          }
        }

        &_2 {
          width: 25rem;
          height: 12.8rem;
          margin: 5.6rem 0 6.4rem;
          @include mobileMedia {
            width: 80px;
            height: 62px;
            margin-top: 2.3rem;
            margin-bottom: 1.9rem;
            margin-left: 127px;
            margin-right: 129px;
          }
        }
      }
    }
  }

  &__feature-text {
    font-family: Gilroy, Arial, sans-serif;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.33;
    color: white;
    text-align: center;

    @include mobileMedia {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.57;
      padding: 0 26px;
    }
  }

  &__content-item {
    display: none;

    &_active {
      display: block;
    }
  }

  &__rules {
    // margin-bottom: 8rem;
    color: white;
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
    max-width: 84rem;

    @include mobileMedia {
      margin-bottom: 8rem;
    }

    & .coefficients-table {
      display: grid;
      grid-template: repeat(11, 1fr) / repeat(11, 1fr);
    }

    & p,
    & .rules-ph {
      font-family: Lato, Arial, sans-serif;
      font-size: 1.6rem;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 3.2rem;
      opacity: 0.9;

      & .numgreen {
        color: #339966;
      }

      & .numred {
        color: #ff0604;
      }

      @include mobileMedia {
        margin-bottom: 2.4rem;
        opacity: 0.8;
      }

      .game-bottom__rules-section:last-child & {
        margin-bottom: 0;
      }
    }

    & .rules-paragraph {
      margin-bottom: 1.6rem;
    }

    & figure {
      display: block;
      margin: 0 auto 5.6rem;
      max-width: 84rem;
      max-height: 32.8rem;
      // width: 100%;
      width: fit-content;
      // height: 30rem;
      background-size: cover;
      text-align: center;

      @include mobileMedia {
        margin-bottom: 3.2rem;
      }
    }

    & img {
      max-width: 84rem;
      max-height: 32.8rem;

      @include mobileMedia {
        width: 100%;
      }
    }

    & .rules-image__withoutWrapper {
      text-align: center;
    }

    h1 {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 4rem;
      line-height: 40px;
      color: #FFFFFF;
      margin-top: 5.6rem;
      margin-bottom: 2.4rem;
      @include mobileMedia {
        margin-top: 4.8rem;
        font-size: 3.2rem;
      }
    }

    h1:first-of-type {
      @include mobileMedia {
        margin-top:0;
      }
    }

    & h2,
    & .rules-title {
      font-family: Gilroy, Arial, sans-serif;
      font-size: 3.6rem;
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: 0.9px;
      color: #ffffff;
      margin-bottom: 2.4rem;

      @include mobileMedia {
        margin-bottom: 0.8rem;
        @include title-3;
      }
    }

    & h3 {
      font-family: Gilroy, Arial, sans-serif;
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 1.3;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 1.6rem;
    }

    & ol {
      color: #FFFFFF;
      opacity: 0.8;
    }

    & .tag-ol {
      margin-bottom: 56px;
        @include mobileMedia {
        opacity: 0.8;
      }
    }

    & table {
      font-family: Lato, Arial, sans-serif;
      font-size: 1.6rem;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 3.2rem;

      @include mobileMedia {
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: normal;
        margin-bottom: 2.4rem;
        opacity: 0.8;
      }
    }
    & .tag-table-wrapper {
      overflow-x: auto;
      border-radius: 4px;

      @include mobileMedia { 
        width: 100vw;
        margin-left: -1.6rem;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
      }
    }

    & .tag-table {
      width: 840px;
      table-layout: fixed;
      border-collapse: collapse;
      font-size: 16px;
      text-align: left;
      border-radius: 4px;
      overflow: hidden;

      & thead {
        height: 56px; 
        background-color: #311C5D;

        & th {
          font-family: "Gilroy", sans-serif;
          font-weight: 600;
          font-size: inherit;
          padding-left: 16px;
        }
      }

      & tbody {
        & tr {
          height: 48px;
          background: rgba(49, 28, 93, 0.4);
          
          &:nth-child(odd) {
            background: rgba(49, 28, 93, 0.2);
          }
          
          & td {
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            font-size: inherit;
            padding-left: 16px;
          }
        }
      }
    }

    & .BJ-buttons {
      display: block;
      margin: 5.6rem auto;
      @include mobileMedia {
        margin: 4.8rem auto;
        width: auto;
      }
    }
  }
}
