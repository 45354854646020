@import "../../../scss/common";
.main-slider {
  height: 100%;
  position: relative;

  @include mobileMedia {
    height: 24rem;
  }

  &__arrow-container {
    @include container;
    position: relative;
    height: 0;
    
  }
  &__arrow-down {
    position: absolute;
    left: 4.7rem;
    z-index: 5;
    bottom: calc((var(--vh) * -100) + 7.7rem);

    @include mobileMedia {
      display: none;
    }
  }
  &__slogan-container {
    @include container;
    z-index: 5;
    pointer-events: none;
  }
  &__slogan-wrapper {
    position: relative;
  }
  &__slogan {
    --mainSloganWidth: 42.8rem;
    position: absolute;
    top: 12.8rem;
    left: 0;
    width: 100%;
    overflow: hidden;
    display: flex;
    height: 14.8rem;
    z-index: 3;
    @include mobileMedia {
      top: 2.4rem;
      height: unset;
      flex-direction: column;
    }
  }
  
  &__slogan-main {
    @include title-1;
    color: white;
    width: var(--mainSloganWidth);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    @include mobileMedia {
      width: unset;
    }
  
  }
  
  &__slogan-slider {
    width: calc((100% - var(--mainSloganWidth)));
    flex-shrink: 0;
    @include mobileMedia {
      width: 100%;
      height: 3rem;
    }
  
  }
  &__full {
    height: calc(var(--vh) * 100);

    @include mobileMedia {
      height: 24rem;
    }
  }
}

.slogan-slider {
  pointer-events: none;
  width: 100%;
  height: 100%;
  &__slide {
    display: inline-flex;
    position: absolute;
    align-items: center;
    right: 0;
    transition: 0.2s right ease-in-out, 0.2s scale ease-in-out, 0.2s opacity ease-in-out;
    text-transform: uppercase;
    @include title-1;
    color: white;
    transform: scale(0.6);
    transform-origin: right;
    white-space: nowrap;

    @include mobileMedia {
      opacity: 0;
    }
    .swiper-slide-active &{
      right: auto;
      transform: scale(1);
      
      @include mobileMedia {
        opacity: 1;
      }
    }
  }
  
  .swiper-container {
    height: 100%;
  }
  
  .swiper-slide {
    display: flex;
    align-items: center;
  }
}

.full-slider {
  
  height: 100%;
  &__img {
    object-fit: cover;
    height: 100%;
    width: 100%;

    &__gradient {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../../../video/video_gradient.svg');
      background-size: cover;
    }
  }
  
  &__separator-contain {
    position: absolute;
    left: calc((100vw - 136.6rem)/2 + 100.6rem - 0.8rem);
    top: 0;
    height: calc(var(--vh) * 100);
    z-index: 2;

    @include mobileMedia {
      position: absolute;
      top: 0;
      left: unset;
      right: 2.7rem;
      height: 100%;
    }
  }
  
  &__separator {
    display: block;
    width: 1.6rem;
    background: $color-main;
    height: 100%;

    @include mobileMedia {
      width: 1rem;
    }
  }
  
  &__arrow-contain {
    position: absolute;
    top: 12.8rem;
    height: 21.4rem;
    width: 7.2rem;
    @include center;
    left: calc((100vw - 136.6rem)/2 + 100.6rem - 3.6rem);
    z-index: 4;

    @include mobileMedia {
      left: unset;
      right: 0.9rem;
      top: unset;
      bottom: 30%;
      height: auto;
      width: 4.6rem;
    }
  }
  
  &__arrow-decor {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    left: 0;
    @include center;
    @include svg;
  }
  
  &__arrow {
    width: 4.8rem;
    height: 4.8rem;
    @include center;
    z-index: 2;

    @include mobileMedia {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
  
  .swiper-container {
    height: 100%;
  }
  .swiper-slide {
    width: calc((100vw - 136.6rem)/2 + 100.6rem);

    @include mobileMedia {
      // 2.7rem  + 1rem - this is separator position
      width: calc(100vw - 3.7rem)
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.8;
      transition: 0.2s opacity ease-in-out;
      background-color: #06071f;
    }
  }
  
  .swiper-slide-active {
    &:after {
      opacity: 0;
    }
  }
}


