@import "../../../scss/common";

.company-form {
  &__container {
    width: 100%;
    background-color: #131a60;
  }

  &__feedback {
    width: 100%;
    border-bottom: 4px;
    background-color: #131a60;

    &_wrapper {
      display: flex;
      margin-bottom: 4.8rem;

      @include mobileMedia {
        margin-bottom: 2.4rem;
      }
    }

    &_text {
      font-family: "Lato";
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.2px;
      color: #a2b6ef;

      @include mobileMedia {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        letter-spacing: normal;
      }
    }

    &_img {
      width: 56px;
      height: 56px;
      background-image: linear-gradient(to right, #0eabdb, #018ef5);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 3.2rem;
      

      @include mobileMedia {
        margin-right: 1.6rem;
        height: 4rem;
        width: 5rem;
      }
    }

    &_title {
      font-family: "Gilroy";
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: #fff;

      @include mobileMedia {
        font-size: 24px;
        line-height: 1.33;
        letter-spacing: normal;
      }
    }
  }

  &__wrapper {
    display: flex;
    margin-bottom: 2.4rem;
    gap: 2.4rem;

    @include mobileMedia {
      display: block;
      margin-bottom: 1.6rem;

      & > div:first-child {
        margin-bottom: 1.6rem;
      }
    }
  }

  &__form {
    border-radius: 0.4rem;
  }

  &__title {
    @include subtitle;
    color: white;
    margin-bottom: 4.8rem;

    @include mobileMedia {
      font-size: 24px;
      margin-bottom: 2.4rem;
    }
  }

  &__textarea {
    margin-bottom: 24px;
    width: 100%;
  }

  &__field {
    width: 50%;

    @include mobileMedia {
      width: 100%;
    }

    &__combo {
      display: flex;

      @include mobileMedia {
        display: block;

        & .input-field {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          backface-visibility: hidden;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &__input {
    @include input;
  }

  &__img {
    margin-right: 16px;
  }

  &__btn {
    margin-top: 5.4rem;

    @include mobileMedia {
      width: 100%;
      height: 4.8rem;
      margin-top: 4rem;
    }
  }

  &__privacy {
    margin-top: 2.8rem;
    margin-bottom: 9.4rem;

    @include mobileMedia {
      margin-top: 1.6rem;
      margin-bottom: 4rem;
    }
  }

  &__label {
    position: relative;
    display: flex;
  }

  &__input-check {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__file {
    position: relative;
    font-family: "Lato";

    &_error {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.18px;
      color: #ff335a;
    }

    &_text {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.2px;
      color: #a2b6ef;
      margin-right: 8px;
      cursor: pointer;

      &:hover {
        color: #fff;
      }

      @include mobileMedia {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        letter-spacing: normal;
      }
    }

    &_formatText {
      opacity: 0.5;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.2px;
      color: #a2b6ef;

      @include mobileMedia {
        display: block;
        font-size: 12px;
        line-height: 1.83;
        letter-spacing: 0.15px;
      }
    }
  }

  &__inputUpload {
    display: none;
  }

  &__check {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.4rem;
    background: $color-blue-11;
    transition: 0.2s background ease-in-out;
    margin-right: 1.6rem;

    .company-form__input-check:checked ~ & {
      background: url("../../../img/check-active.svg") $color-blue-11;
    }
  }

  &__label-text {
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
    color: white;
    transition: 0.2s color ease-in-out;

    .contacts-form__input-check:checked ~ & {
      color: $color-text-light;
    }
  }
}
