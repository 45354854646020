@import "../../../scss/common";

.company-careers {
    overflow: hidden;
    width: 100%;
    max-width: 840px;
    margin: 0 auto;
    margin-bottom: 232px;

    &__title {
        margin-bottom: 4.8rem;
        font-family: 'Gilroy';
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 1px;
        text-align: center;
        color: #fff;

        @include mobileMedia {
            margin-bottom: 2.4rem;
            font-size: 24px;
            line-height: 1.33;
            letter-spacing: normal;
        }
    }
    
    @include mobileMedia {
        margin-bottom: 80px;
        width: calc(100% - 32px);
    }

    &__accardion {
        &_respond {
            background-color: #0C0C4D !important;

            &::before {
              content: none;
            }
        }
    }

    &__whiteText {
        color: #fff;
    }

    &__nameStep1 {
        width: 250px;
        font-family: Gilroy;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        line-height: 1.45;
        color: #fff;

        @include mobileMedia {
            font-size: 16px;
            font-weight: 800;
            width: 12rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            line-height: 1.5;
        }
    }

    &__nameStep2 {
      width: 250px;
      font-family: Gilroy;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: 0.5px;
      color: #fff;
      display: block;

      @include mobileMedia {
          font-size: 14px;
          font-weight: 800;
          width: 12.5rem;
          display: flex;
          justify-content: flex-start;
          margin: 0;
      }
  }

    &__grayText {
        margin: 8px auto 8px auto;
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #a2b6ef;
        position: relative;
        left: -66px;
        text-align: center;

        @include mobileMedia {
            margin: 4px auto 4px auto;
            font-family: Gilroy;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #a2b6ef;
            left: -20px;
        }
    }

    &__openCloseIcon {
        width: 40px;
        height: 40px;
        padding: 12px;
        border-radius: 4px;
        background-color: #2d4185;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @include mobileMedia {
            width: 32px;
            height: 32px;
            padding: 8px;
        }
    }

    &__openCloseWrapper {
        width: 40px;
        height: 40px;
        padding: 12px;
        border-radius: 4px;
        color: white;
        display: flex;
        align-items: center;
        cursor: pointer;

        @include mobileMedia {
            width: 32px;
            height: 32px;
            padding: 8px;
        }
    }

    &__svg {
        transition: 0.2s;

        &_active {
            transition: 0.2s;
            transform: rotate(-45deg);
        }

        &_close {
            display: none
        }

        &_open {
            display: block;
        }
    }
    &__wrapper {
      margin-top: 2rem;
    }
    &__intoAccardion {
        margin-top: 20px;
        border-top: 2px solid #131a60;
        box-shadow: none !important;
        max-width: 785px;
        margin: 0 16px!important;

        @include mobileMedia {
          margin: 0!important;
        }

        & > div {
          background-color: #0C0C4D;
        }

        &:last-child {
          border-bottom: 2px solid #131a60;
        }

        &.MuiPaper-root {
          background-color: transparent;
        }
    }

    &__requirements {
        display: block !important;
        padding: 0 !important;

        &_item {
            font-family: Lato;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.2px;
            color: #a2b6ef;
            margin-bottom: 10px;

            & > h4 {
              color: #fff;
              font-family: Gilroy;
              font-weight: bold;
              margin-bottom: 1.6rem ;

              @include mobileMedia {
                font-size: 14px;
              }
            }

            & li {
              list-style-image: url('./career-icon-check.svg');
              padding-left: 0.8rem;
              margin-bottom: 0.6rem;
               @include mobileMedia {
                padding-left: 0.4rem;
               }
            }

            @include mobileMedia {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.57;
                letter-spacing: normal;
            }
        }

        &_check {
            color: #015cf5;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 11px;
            font-size: 10px;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__button {
        width: 13.4rem;
        height: 5.6rem;
        margin: 4rem 0;
    }

    &__form {
        display: block;
        width: 100%;
        padding: 5.6rem 3.2rem; 
        border-radius: 4px;
        background-color: #131a60;
                    
        @include mobileMedia {
            padding: 4rem 1.6rem;
        }
    }
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(0deg) !important;
    .MuiIconButton-label {
        .company-careers__svg_close {
            display: block;
        }
        .company-careers__svg_open {
            display: none;
        }
        .company-careers__openCloseIcon {
            svg {
                transition-duration: 250ms;
                transform: rotate(45deg);
            }
        }
    }
}
.MuiAccordionSummary-expandIcon {
  transform: rotate(0deg) !important;
  .MuiIconButton-label {
      .company-careers__openCloseIcon {
          svg {
              transition-duration: 250ms;
              transform: rotate(0deg);
          }
      }
  }
}

.MuiAccordionSummary{
  &-content {
    align-items: center;
  }
}

.MuiAccordion-root:before {
  content: none !important;
}

.MuiAccordion-root.Mui-expanded {
  margin-top: 0 !important;
}
