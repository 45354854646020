@import "../../../scss/common";
.prev-button {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  @include bg-gradient-dark;
  @include center;
  @include svg;
  border-radius: 0.4rem;
  overflow: hidden;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include bg-gradient-dark-hover;
    opacity: 0;
    transition: 0.3s opacity ease-in-out;
    z-index: 1;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
  svg {
    position: relative;
    fill: white;
    width: 1.6rem;
    height: 1.6rem;
    z-index: 2;
  }
  
  &_game {
    &_keno {
      background: #330286;
      &:after {
        background-image: linear-gradient(to right, #5600c5 -21%, #330286);
      }
    }
    
    &_ler {
      background: rgba($color-gray, 0.57);
      &:after {
        background: linear-gradient(to right, rgba(#000000, 0.57) 20%, rgba(#151617, 0.57) 92%);
      }
    }

    &_ar, &_bj, &-cr {
      background: #151617;
      &:after {
        background: linear-gradient(to right, rgba(#000000, 0.57) 20%, rgba(#151617, 0.57) 92%);
      }
    }
    &_bc {
      background: linear-gradient(45deg, #5A2B1B 49.5%, #743621 100%);
      &:after {
        background: #743621;
      }
    }
  }
}
