@import "../../../scss/common";

.company-top {
  position: relative;
  height: 40rem;
  padding-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  max-width: 136.6rem;
  margin: 0 auto;
  padding: 0 4.7rem;
  margin-top: 80px;

  @include mobileMedia {
    height: 20.5rem;
    margin: 0;
    padding: 0;
  }

  &__bg {
    position: absolute;
    top: 0;
    height: 32rem;
    width: 93.1%;
    transition: 0.3s transform ease-in-out;

    @include mobileMedia {
     content: url("../../../img/top-bg-mobile.jpg");
     width: auto;
     height: auto;
    }
  }

  &__container {
    @include container;
    position: relative;
    z-index: 2;
  }
  
  &__title {
    @include title-5;
    color: white;
    margin-bottom: 4.8rem;
    line-height: 1.17;
    letter-spacing: 0.5rem;
    text-align: center;
    
    @include mobileMedia {
      margin-bottom: 20px;
    }
  }

  &__vacancyInfo {
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #a2b6ef;

    & > span {
      color: #fff;
      margin-left: 10px;
    }
  }
}

