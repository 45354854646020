@import "../../../scss/common";
.arrow-down {
  $del: 0.1s;
  cursor: pointer;

  @keyframes space-between {
    0%, 100% {
      margin-bottom: 0.8rem;
    }
    50% {
      margin-bottom: 0.2rem;
    }
  }

  @keyframes fill-down {
    0%{
      fill: $color-blue-2;
    }
    100%{
      fill: white;
    }
  }

  @keyframes updown {
    0%, 100% {
      transform: translateY(0%);
    }
    50% {
      transform: translateY(25%);
    }
  }

  animation: updown 1.4s ease-in-out infinite;

  &__part {
    width: 1.4rem;
    height: 0.8rem;
    @include svg;
    @include center;
    animation: space-between 1.4s infinite;

    svg {
      fill: rgba(white, 0.9);
      animation: fill-down 1.4s infinite;
    }
    &:nth-child(2) {
      svg{
        animation-delay: $del;
      }
     
    }
    &:nth-child(3){
      margin-bottom: 0;
      svg {
        animation-delay: $del * 2;
      }
    }
  }
}