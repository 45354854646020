@import "../../../scss/common";

.studio {

  
  @include mobileMedia {
    padding-top: 8rem;
    overflow: hidden;
  }
  
  &__inner {
    @include container;
    display: flex;
    padding: 6.4rem 4.7rem 0;

    @include mobileMedia {
      padding-right: 0;
      padding-left: 1.6rem;
      display: block;
      margin: 0;
      width: 30.4rem;
    }
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {

    @include mobileMedia {
      width: 100%;
      &:last-child{
        margin-right: calc( 26.4rem - 100vw) !important;
      }
    }
  }

  &__items, &__images {
    flex: 0 0 50%;
    min-height: 52.8rem;
  }
  
  &__items {
    background: $color-blue-4;
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
    overflow: hidden;
  }
  
  &__item {
    position: relative;
    height: 17.6rem;
    padding: 2.4rem 0;
    opacity: 0.5;
    background: $color-blue-4;
    transition: 0.2s opacity ease-in-out, 0.2s background ease-in-out;
    display: flex;
    cursor: pointer;
    
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 0.6rem;
      height: 100%;
      background-image: linear-gradient(to right, $color-blue-2, $color-blue-1);
      opacity: 0;
      transition: 0.2s opacity ease-in-out;

      @include mobileMedia {
        display: none;
      }
    }
    
    &:hover {
      opacity: 1;
    }
    
    &_active {
      opacity: 1;
      background: rgba($color-blue-5, 0.2);
      cursor: auto;
      
      &:after {
        opacity: 1;
      }
    }

    
    &__mobile {
      border-radius: 0.4rem;
      overflow: hidden;
    }
  }
  
  &__item-content {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 4.5rem;
    padding-right: 2rem;

    @include mobileMedia {
      padding-left: 0;
      width: 19.2rem;
      padding-right: 2.4rem;
      box-sizing: content-box;
    }
  }
  
  &__item-title {
    font-family: Gilroy, Arial, sans-serif;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.33;
    color: #ffffff;
    margin-bottom: 1.6rem;

    @include mobileMedia {
      font-size: 1.6rem;
      line-height: 1.5;
    }
  }
  
  &__item-text {
    opacity: 0.9;
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    color: #ffffff;

    @include mobileMedia {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.67;
      color: $color-text-light;
    }
  }
  // &__title {
  //   @include title-2;
  //   text-align: center;
  //   margin-bottom: 6.4rem;
  // }
  
  &__item-img {
    height: 8.8rem;
    width: 8.8rem;
    flex: 0 0 auto;
    margin-top: 1.6rem;
    margin-left: 1.6rem;
    background-repeat: no-repeat;
    background-position: center;
    @include mobileMedia {
      margin-top: 0;
      height: 5.6rem;
      width: 5.6rem;
      margin-left: .8rem;
      margin-right: .8rem;
      background-size: contain;
    }
  }
  
  &__images {
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    overflow: hidden;
    position: relative;
  }
  
  &__img-cont {
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0;
    transition: 0.2s opacity ease-in-out;

    @include mobileMedia {
      position: static;
      height: 19.6rem;
    }
    
    &_active {
      opacity: 1;
    }
  }
  
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
