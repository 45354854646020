@import "../../../scss/common";

.our-games {
  padding: 2rem 0;

  @include mobileMedia {
    padding: 8rem 0 0;
    overflow: hidden;
  }
  &__container {
    @include center;
  }

  &__slider {
    height: 52rem;

    @include mobileMedia {
      // 21.6 + 4 rem - pagination padding
      height: calc(21.6rem + 4rem); 
      width: 30.4rem;
      padding-left: 1.6rem;
    }
  }
}
