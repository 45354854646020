@import '../../../scss/common';

.nav__button {
  display: flex;
  // @include center;
  // height: 4.8rem;
  align-items: center;
  color: #ffffff;
  transition: 0.3s color ease-in-out;

  &:hover {
    cursor: pointer;
    @media (pointer: fine) {
      color: $color-text-light;
    }
    .arrow {
      &:after, &:before {
        transform: scale(1.16);
        @include mobileMedia {
          transform: scale(1.1)
        }
      }
  
      &:before{
        opacity: 1;
      }
    }
    
  }

  &-text {
    opacity: 0.9;
    padding: 0 0.8rem;
    margin-right: 0.8rem;
    @include text-4;

    @include mobileMedia {
      display: none;
    }
    
    &_dense {
      opacity: 1;
      font-family: Gilroy, Arial, sans-serif;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.71;
      letter-spacing: 0.078rem;
      text-transform: uppercase;
    }
    
    .news__item-link & {
      opacity: 1;
      font-family: Gilroy, Arial, sans-serif;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.71;
      letter-spacing: 0.078rem;
      text-transform: uppercase;
    }
  }

  &-arrow {
    height: 3.2rem;
    width: 3.2rem;
  }
}
