@import "../../../scss/common";

.animated_container {
    position: relative;
  }
  
  .animated_page {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow:auto;
    overflow-x: hidden;
    background: $color-main;
    //will-change: transform; ломал кнопку Х закрытия
  }
  
  .animated_page-enter {
    transform: translateY(100%);
  }
  
  .animated_page-enter-active {
    transform: translateY(0%);
    transition: transform 300ms ease-in-out;
  }

  .animated_page-exit {
    transform: translateY(0%);
    transition: transform 300ms ease-in-out;
  }
  
  .animated_page-exit-active {
    transform: translateY(100%);
    transition: transform 300ms ease-in-out;
  }
  