@import "../../../../scss/common";

.become-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 14.4rem;
  margin-bottom: 5.6rem;

  @include mobileMedia {
    margin-bottom: 1.6rem;
    height: unset;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 4rem;
    bottom: 0;
    transform: translateY(100%);
    width: 0.1rem;
    height: 5.5rem;
    border-left: dotted 0.2rem $color-blue-11;

    @include mobileMedia {
      display: none;
    }
  }
  
  &:last-child {
    &:after {
      display: none;
    }
  }
  
  &__number {
    width: 8rem;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-blue-6;
    border-radius: 50%;
    font-family: Gilroy, Arial, sans-serif;
    font-size: 2rem;
    font-weight: 900;
    line-height: 1.2;
    letter-spacing: 0.25rem;
    text-align: center;
    color: $color-blue-light-2;

    @include mobileMedia {
      position: absolute;
      left: 1.6rem;
      top: 2.4rem;
      background-color: unset;
      width: unset;
      height: unset;
      z-index: 1;
      
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0.1rem;
    }
  }
  
  &__main {
    position: relative;
    height: 100%;
    width: 70rem;
    border-radius: 0.4rem;
    transform-style: preserve-3d;
    transition: all 0.7s ease;

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 1.1rem;
      height: 1.1rem;
      transform: rotate(-315deg);
      top: calc(50% - 0.55rem);
      left: -0.5rem;
      background-color: $color-blue-6;

      @include mobileMedia {
        display: none;
      }
    }
  }

  &__front, &__back {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3.2rem;
    position: responsive;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-color: $color-blue-6;

    @include mobileMedia {
      padding: 2.4rem 0.8rem 2.4rem 5.6rem;
      align-items: flex-start;
    }
  }

  &__back {
    transform: rotateX(180deg);
    position: absolute;
    overflow: hidden;
    border-radius: 0.4rem;

    &__progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 2px;
      background-color: #5be587;  
      transition: width 3s ease-in 0.5s; 
      width: 100%;
      
      &.active {
        width: 0;
      }
    }
  }

  &__icon {
    margin-top: -0.8rem;
    width: 8.8rem;
    height: 8.8rem;
    @include mobileMedia {
      margin-top: -1.6rem;
      width: 5.6rem;
      height: 5.6rem;
      flex-shrink: 0;
    }
  }
  
  &__title {
    font-family: Gilroy, Arial, sans-serif;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.67;
    color: #ffffff;
    margin-bottom: 0.8rem;

    @include mobileMedia {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 0.02rem;

    }
  }
  
  &__text {
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.08rem;
    color: $color-text-light;

    
    @include mobileMedia {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.57;
      letter-spacing: 0.02rem;

    }
  }
  
  
}

