@import "../../scss/common";


.game-bottom__tabs_cont {
  display: flex;
  width: 100%;
  height: 100px;
  position: absolute;
  z-index: 10;
  top: 100px;
  justify-content: center;
  align-items: center;
  &.tabs_scrolled{
    display: flex
  }
  .itm{
    color: yellow;
    margin: 100px;
    cursor: pointer;
    font-size: 16px;
    &.selected{
      color: red
    }
  }
}


.game-page {

  
  @include mobileMedia {
    background-image: linear-gradient(to bottom, #000d29, #000616);
  }

  &__tabs-container {
    display: none;
    width: 100%;
    position: absolute;
    z-index: 10;
    top: 92px;
    &.tabs_scrolled{
      display: flex;
      justify-content: center;
    }
  }

  &__tabs {
    width: 84rem;
    display: flex;
    position: relative;


    @include mobileMedia {
      width: 100%;
      padding-top: 1.6rem;
      margin-bottom: 3.2rem;
      .game-page_game_keno & {
        background-image: linear-gradient(to right, #0d0319 5%, #0d0230 96%);
      }

      .game-page_game_ler, .game-page_game_ar, .game-page_game_bj, .game-page_game-cr, .game-page_game_bc  & {
        background: #000000;
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 0.4rem;
      width: 100%;
      left: 0;
      bottom: 0;
      background: $color-blue-4;
      border-radius: 0.2rem;
      z-index: 1;
    }
    &_game {

      &_ler, &_ar, &_bj, &-cr, &_bc {
        &:after {
          background: $color-gray;
        }
      }
    }
    &.hide_tabs{
      visibility: hidden;
    }
  }

  &__tag {
    display: block;
    position: absolute;
    height: 0.4rem;
    width: calc(100% / 2);
    left: 0;
    bottom: 0;
    background: $color-blue-10;
    border-radius: 0.2rem;
    z-index: 2;
    transition: 0.64s transform cubic-bezier(0.65, 0.05, 0.36, 1);

    &_pos_1 {
      transform: translateX(0);
    }

    &_pos_2 {
      transform: translateX(100%);
    }

    &_pos_3 {
      transform: translateX(200%);
    }

    &_game {

      &_keno {
        background-image: linear-gradient(
                        to bottom,
                        $color-keno-gradient-1 1%,
                        $color-keno-gradient-1
        );
      }

      &_ler, &_ar, &, &-cr {
        background-image: linear-gradient(
                        to left,
                        $color-ler-gradient-1,
                        $color-ler-gradient-2 2%
        );
      }
    }
  }
  &__tab {
    position: relative;
    font-family: Gilroy, Arial, sans-serif;
    font-size: 2.4rem;
    height: 6.5rem;
    padding-bottom: 1.5rem;
    width: 42rem;
    cursor: pointer;
    @include center;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-align: center;
    color: rgba(white, 0.8);
    text-transform: uppercase;
    transition: 0.3s color ease-in-out;

    @include mobileMedia {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 2.5;
      letter-spacing: 2px;
      width: 50%;
      height: 4rem;
      padding-bottom: 0.4rem;
    }

    &_game {

      &_keno {
        &:after {
          background-image: linear-gradient(
                          to bottom,
                          $color-keno-gradient-1 1%,
                          $color-keno-gradient-1
          );
        }
      }

      &_ler, &_ar, &_bj, &_bc {
        &:after {
          background-image: linear-gradient(
                          to left,
                          $color-ler-gradient-1,
                          $color-ler-gradient-2 2%
          );
        }
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 0.4rem;
      width: 100%;
      left: 0;
      bottom: 0;
      background: $color-blue-10;
      border-radius: 0;
      opacity: 0;
      transition: 0.2s opacity ease-in-out;
      z-index: 2;
    }

    &:first-child {
      &:before {
        border-bottom-left-radius: 0.2rem;
        border-top-left-radius: 0.2rem;
      }
    }

    &:last-child {
      &:before {
        border-bottom-right-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
      }
    }

    &_active {
      color: white;
      &:after {
        opacity: 1;
      }
    }

    &:hover:not(&_active) {
      @media (pointer: fine) {
        color: white;
      }
    }
  }
  
  &_game {
    &_keno {
      background-image: linear-gradient(to right, #0d0319 5%, #0d0230 96%);
    }
    
    &_ler, &_ar, &_bj, &-cr, &_bc {
      background: #000000;
    }
  }
  
  &__top {
    @include desktopMedia {
      &:after {
        content: '';
        position: absolute;
        z-index: 10;
        display: block;
      }
    }
    &_game {
      &_keno {
        &:after {
          right: -17.7rem;
          width: 55.1rem;
          height: 59.2rem;
          top: calc((var(--vh) * 100) - 28.8rem);
          background: url("../../img/game-page/keno/decor/blur.png") no-repeat;
          background-position: center;
          opacity: 0.54;
          background-size: 100%;
        }
      }
    }
  }
}
