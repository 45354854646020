@import "../../../../scss/common";

.item-list {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  background: $color-blue-6;
  border-radius: 0.4rem;
  overflow: hidden;
  cursor: pointer;

  &__soon {
    pointer-events: none;
  }
  
  &__img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;

    @include mobileMedia {
      height: auto;
      width: auto;
    }
  }
  
  &__content {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 4.8rem 4rem;
    transition: 0.2s visibility ease-in-out, 0.2s opacity ease-in-out;

    .item-list:not(.item-list__soon):hover > & {
      visibility: hidden;
      opacity: 0;
    }

    @include mobileMedia {
      padding: 2.4rem;
      .item-list:not(.item-list__soon):hover > &  {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  
  &__title {
    @include title-4;
    text-transform: none;
    letter-spacing: normal;
    margin-bottom: 0.8rem;

    @include mobileMedia {
      @include title-3;
      margin-bottom: 0.8rem;
      letter-spacing: normal;
    }
  }

  &__text {
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    color: #ffffff;

    @include mobileMedia {
      @include text-5;

      @include mobileMedia {
        display: flex;
        align-items: baseline;
      }
    }

    &__year {
      display: none;
      @include mobileMedia {
        display: inline;
        font-family: Lato;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: normal;
        color: #ffffff;
        margin-left: auto;
      } 
    }
  }

  &__status {
    position: absolute;
    top: 4.8rem;
    font-family: Gilroy, Arial, sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.1rem;
    color: #f4dc55;
    text-transform: uppercase;

    @include mobileMedia {
      top: 2.4rem;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.6;
      letter-spacing: 0.1rem;
    }
  }
  
  &__info {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba($color-dark-2, 0.85);
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s visibility ease-in-out, 0.2s opacity ease-in-out;
    *:hover > & {
      visibility: visible;
      opacity: 1;
    }
  }
  
  &__logo {
    width: 36rem;
    height: 10.4rem;
    text-align: center;
    margin: 11.4rem auto 3.2rem;
  }
  
  &__logo-img {
    height: 100%;
    width: auto;
  }
  
  &__btns {
    display: flex;
    justify-content: center;
  }
  
  &__btn {
    height: 4.8rem;
    width: 16.8rem;
  }
  
  &__date {
    margin-bottom: 3.2rem;
    margin-top: auto;
    opacity: 0.8;
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    color: #ffffff;
    text-align: center;
  }
}
