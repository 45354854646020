@import "../../../../scss/common";

.our-games-slider {
  height: 100%;
  width: 100%;
  padding-top: 4rem;
  position: relative;

  @include mobileMedia {
    padding-top: 0;
  }
  
  &__container {
    height: 100%;
    width: 100%;
    padding-bottom: 4rem;
    mask-image: linear-gradient(to left, transparent 0, black 50rem, black calc(100% - 50rem), transparent 100%);

    @include mobileMedia {
      mask-image: unset;
    }
  }
  
  &__arrow-container {
    width: 136.6rem;
    flex-shrink: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 2;
  }

  
  &__slide {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
    &_soon {
      cursor: default;
      pointer-events: none;
    }
  }

  
  &__img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 0.4rem;
  }
  
  &__status {
    position: absolute;
    top: 4.8rem;
    left: 4rem;
    font-family: Gilroy, Arial, sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.1rem;
    color: #f4dc55;
    text-transform: uppercase;
    
    @include mobileMedia {
      top: 2.4rem;
      left: 2.4rem;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.6;
      letter-spacing: 0.1rem;
    }
  }
  
  &__content {
    position: relative;
    z-index: 2;
    padding: 4.8rem 4rem;
    color: white;

    @include mobileMedia {
      padding: 2.4rem;
    }
  }
  
  &__title {
    @include title-3;
    margin-bottom: 0.8rem;
    
  }
  
  &__text {
    @include text-5;

    @include mobileMedia {
      display: flex;
      align-items: baseline;
    }
  }
  
  &__year {
    display: none;

    @include mobileMedia {
      display: inline;
      font-family: Lato;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: normal;
      color: #ffffff;
      margin-left: auto;
    } 
  }
  
  &__arrow {
    position: absolute;
    z-index: 4;
    height: 4.8rem;
    width: 4.8rem;
    top: 50%;
    transform: translateY(-50%);

    @include mobileMedia {
      display: none;
    }
    &_prew {
      left: 4.7rem;
    }
    
    &_next {
      right: 4.7rem;
    }
  }
  
  &__pagination {
    text-align: center;
    position: absolute;
    bottom: calc(-1.6rem - 16px) !important ;
    display: flex;
    justify-content: center;
    height: 3rem;
    align-items: center;
    @include mobileMedia {
      width: calc(100vw - 3.2rem) !important;
    }

  }
  
  &__pagination-bullet {
    position: relative;
    display: block;
    opacity: 1;
    width: 4rem;
    height: 3rem;
    margin: 0 0.8rem;
    overflow: hidden;
    cursor: pointer;
    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 0.2rem;
      left: 0;
      top: 1.4rem;
    }
    &:before {
      background: $color-blue-3;
      border-radius: 0.2rem;
    }
    &:after {
     
      transform: translateX(-100%);
      background: $color-blue-3;
      z-index: 3;
    }
    &_active {
      &:before {
        background: #131A60;
      }
     &:after {
       transform: translateX(0);
       transition: 4s transform ease-in-out;
     }
      & ~ .our-games-slider__pagination-bullet:before {
        background: #131A60;
      }
    }
  }
  
  .swiper-container {
    height: 100%;
    overflow: visible;
  }
  
  .swiper-wrapper {
    transition-timing-function: cubic-bezier(0.65,0.05,0.36,1) !important;
  }
  
  .swiper-slide {
    width: 62.4rem;
    position: relative;
    border-radius: 0.4rem;
    overflow: hidden;

    @include mobileMedia {
      width: 100%;
      &:last-child{
        margin-right: calc( 26.4rem - 100vw);
      }
    }
    &:before {
      content: '';
      position: absolute;
      height: 12rem;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      background-image: linear-gradient(to bottom, rgba(3, 15, 36, 0), rgba(3, 15, 36, 0.5) 39%, #030f24);
      z-index: 1;
    }
    &:after {
      @include pseudo-total;
      background: rgba($color-main, 0.4);
      opacity: 1;
      transition: 0.2s opacity ease-in-out;
    }
    
    &.swiper-slide-active {
      &:after {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}