@import "../../../scss/common";
.dropdown {
  height: 100%;
  min-width: 9.2rem;
  position: relative;
  display: block;
  cursor: pointer;
  overflow: visible;
  z-index: 10;
  margin-right: .8rem;

  @include mobileMedia {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
  }

  @include mobileMedia {
    width: 100%;
    margin-bottom: 0.1rem;
    margin-right: 0;
  }

  &__icon {
    margin-right: 2rem;

    @include mobileMedia {
      margin-right: 0;
      .dropdown__select__item & {
        opacity: .5;
      }
      .dropdown__select__item.is__selected & {
        opacity: 1;
      }
    }
  }

  &__arrow {
    transition: .3s;
    &.is__open {
      transform: rotate(180deg);
    }
  }
  
  &__btn {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    background: $color-blue-11;
    border-radius: 0.4rem;
    z-index: 10;

    @include mobileMedia {
      display: none;
    }
    
    padding-left: 2rem;
    &:hover, &:focus {
      @media (pointer: fine) {
        background-color: $color-blue-12;
      }
   }


    svg {
      @include svg;
      fill: white;
      transition: fill 0.2s ease-in-out;
    }
  
    *:hover > & {
      svg {
        @media (pointer: fine) {
          fill: $color-text-light;
        }
      }
    }
  }
  
  &__arrow {
    width: 1.6rem;
    height: 1.6rem;
    @include svg;

  }  
}


// Select

.dropdown__select {
  position: relative;
  width: 100%;
  background: $color-blue-12;
  border-radius: 0.4rem;
  z-index: 5;
  bottom: -0.8rem;
  // 5.6 rem height of input filed - 0.8 rem bottom 
  transform: translateY(-4.8rem);
  opacity: 0;
  cursor: default;
  pointer-events: none;
  transition: opacity 300ms ease-in-out, transform 300ms ease;

  @include mobileMedia {
    position: static;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    opacity: 1;
    pointer-events: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transform: unset;
    background: $color-blue-11;
  }

  &.is__open {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
 
  &__item {
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 2rem;

    @include mobileMedia {
      padding-left: 0;
      justify-content: center;
    }

    &:first-child {
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
      @include mobileMedia {
        border-radius: 0.4rem 0 0 0;
      }
    }

    &:last-child {
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      @include mobileMedia {
        border-radius: 0 0.4rem 0 0;
      }
    }
    
    &:hover {
      cursor: pointer;
      @media (pointer: fine) {
        background-color: $color-blue-hovered;
      }

      @include mobileMedia {
        background-color: $color-blue-11;
      }

      &::after {
        background-color: $color-blue-hovered;
      }
    }
  }
}