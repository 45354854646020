@import '../../../../scss/common';

.contacts-form {
  background: $color-blue-6;
  border-radius: 0.4rem;
  padding: 5.6rem;

  @include mobileMedia {
    padding: 4rem 1.6rem;
  }

  &__field {
    @include field;
  }

  &__input {
    margin-bottom: 1.6rem;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
  }

  &__btn {
    height: 5.6rem;
    @include mobileMedia {
      height: 4.8rem;
    }
  }

  &__privacy {
    margin-top: 3.2rem;
    margin-bottom: 5.6rem;

    @include mobileMedia {
      margin-top: 1.6rem;
      margin-bottom: 4rem;
    }
  }
}
