@import '../../../scss/common';
.close {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 50%;
  @include center;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    transition: 0.3s transform ease-in-out, 0.3s opacity ease-in-out;
  }
  
  &_game {

    &_keno {
      &:after {
        background: #150046;
        opacity: 0.6;
      }
    }

    &_ler, &_ar, &_bj, &-cr, &_bc {
      &:after {
        background: $color-gray;
        opacity: 0.6;
      }
    }
  }
  
  
  @include desktopMedia {
    &:hover {
      &:after {
        transform: scale(1.16);
        opacity: 1;
      }
  }
  }

  &__icon {
    position: relative;
    z-index: 3;
    @include center;
    svg {
      width: 2.3rem;
      height: 2.3rem;
      fill: $color-text-light;

      @include mobileMedia {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
    &_game {

      &_keno {
        svg {
          fill: $color-text-light;
        }
      }

  &_ar, &_ler, &_bj, &-cr, &_bc {
        svg {
          fill: white;
        }
      }
    }
  }
}
