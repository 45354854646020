@import '../../scss/common';

.privacy {
  &__top {
    height: 32rem;
    position: relative;
    display: flex;
    align-items: center;
    @include mobileMedia {
      height: 20.5rem;
    }
  }
  
  &__img {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    object-fit: cover;
    top: 0;
    left: 0;
  }
  
  &__inner {
    @include container;
  }
  
  &__inner-main {
    max-width: 84rem;
    margin: 0 auto;
    padding: 0 4.7rem;
    @include mobileMedia {
      padding: 0 1.6rem;
    }
  }
  
  &__title {
    position: relative;
    z-index: 2;
    @include title-2;
    text-align: center;
  }
  
  &__main {
    background-color: #04053c;
    padding-top: 1.6rem;
    padding-bottom: 20.8rem;
    
    @include mobileMedia {
      padding-bottom: 6.8rem;
    }
  }
  
  &__title_2 {
    @include subtitle;
    color: white;
    margin: 5.6rem 0 2.4rem;
    @include mobileMedia {
      margin: 4rem 0 1.6rem;
    }
  }
  
  &__text, &__list {
    opacity: 0.9;
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.02rem;
    color: #ffffff;
    margin: 2.4rem 0;
    
    @include mobileMedia {
      margin: 1.6rem 0;
    }
  
    &_li {
      margin: 0.8rem 0;
      @include mobileMedia {
        margin: 0.6rem 0;
      }
    }
  }
  
  
  
}