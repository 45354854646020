@import '../../../scss/common';

.news-top {
  min-height: 68.8rem;
  max-height: calc(var(--vh) * 100);
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;

  @include mobileMedia {
    min-height: 0;
    align-items: flex-end;
    margin-bottom: 8rem;
  }

  &__img-wrapper {
    position: absolute;
    height: calc(100% - 8rem);
    z-index: 1;
    bottom: 0;
    width: 127.2rem;
    left: 50%;
    transform: translate(-50%);
    border-radius: 4px;
    overflow: hidden;
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: white;
      opacity: 0;
      z-index: 2;
      border-radius: 4px;
    }


    @include mobileMedia {
      height: 38.4rem;
      width: 100%;
      top: 0;

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 18.4rem;
        @include bg-gradient-main;
        z-index: 10;
        opacity: 1;
      }
    }
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition: 0.3s transform ease-in-out;

    @include mobileMedia {
      height: 38.4rem;
      width: 100%;
      object-position: top;
    }
  }

  &__container {
    @include container;
    padding: 7.2rem 15.5rem 5.6rem;
    display: flex;
    height: calc(100% - 8rem);
    min-height: 60.8rem;
    max-height: calc(var(--vh) * 100);
    flex-direction: column;
    justify-content: space-between;
    @include mobileMedia {
      min-height: 0;
      height: auto;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    width: 50%;
    justify-content: center;
    flex-direction: column;


    @include mobileMedia {
      width: 100%;
      height: 38.4rem;
      justify-content: flex-end;
      margin-bottom: 2.4rem;
    }
  }

  &__title {
    @include title-5;
    color: white;
  }

  &__text {
    font-family: Lato, Arial, sans-serif;
    font-size: 2.4rem;
    line-height: 1.33;
    letter-spacing: 0.2px;
    margin-bottom: 5.6rem;
    color: white;

    @include mobileMedia {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0.2px;
      margin-bottom: 3.2rem;
      margin-right: 1rem;
    }
  }

  &__btns {
    display: flex;
  }

  &__btn {
    height: 5.6rem;
    width: 5.6rem;
    position: relative;
    z-index: 3;
    cursor: pointer;

    @include mobileMedia {
      position: fixed;
      top: 1.6rem;
      left: unset;
      right: 1.6rem;
      height: 3.2rem;
      width: 3.2rem;
    }
  }


    &_demo {
      width: 14.9rem;
      height: 5.6rem;
      margin-right: 2.4rem;

      @include mobileMedia {
        height: 4.8rem;
        width: 100%;
        margin-right: 0;

      }

    }

    &_back {
      width: 5.6rem;
    }

  &__info {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 2;

    @include mobileMedia {
      position: static;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__info-part {
    &_author {
      display: flex;
    }
  }

  &__author {
    position: relative;
    width: 9.6rem;
    height: 9.6rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 3.2rem;

    @include mobileMedia {
      width: 5.6rem;
      height: 5.6rem;
      flex-shrink: 0;
      margin-right: 1.6rem;
      align-self: center;
    }
  }

  &__author-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 9.7rem;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: -4.8rem;
      top: 0;
      width: 1px;
      height: 100%;
      opacity: 0.5;
      border-radius: 0.5px;
      background-color: #d8d8d8;
    }

    @include mobileMedia {
      &:after {
        content: none;
      }
      margin-right: 2rem;
    }
  }

  &__author-img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;

    @include mobileMedia {
      height: 5.6rem;
      width: 5.6rem;
    }
  }

  &__info-text {
    font-family: Gilroy, Arial, sans-serif;
    font-size: 2.4rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: white;
    @include mobileMedia {
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: normal;
      margin-bottom: 0;
    }
  }

  &__author-name {
    white-space: nowrap;
  }

  &__author-position {
    opacity: 0.9;
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    color: #ffffff;

    @include mobileMedia {
      font-size: 1.4rem;
    }
  }

  &__type {
    margin-left: auto;
  }

  &__info-date {
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    color: #ffffff;

    @include mobileMedia {
      font-size: 1.4rem;
      white-space: nowrap;
    }
  }
}
