@import "../../../scss/common";
.game-partners {
  position: relative;
  &__container {
    @include container;
    display: flex;
    justify-content: center;  
    flex-wrap: wrap; 
    padding: 0;
    align-items: flex-start;

    & .swiper-slide {
      width: auto;

      &:last-child {
        margin-right: 1.6rem;
      }
    }
    @include mobileMedia {
      justify-content: flex-start;
      overflow: hidden;
      flex-wrap: nowrap;
      padding-right: 0;
      padding-left: 0;
    }
  }
}
