@import "../../../scss/common";

%text{
  font-family: Lato, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  margin: 2rem 0;
  color: rgba(#ffffff, 0.9);

  @include mobileMedia {
    margin-top: 0;
    margin-bottom: 1.4rem;
  }
}

%header-text{
  color: #ffffff;
  font-family: Gilroy, "Arial Black", sans-serif;
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 1px;
  margin-bottom: 4rem;
  @include mobileMedia {
    font-size: 2.4rem;
    line-height: 1.33;
    letter-spacing: normal;
    margin-bottom: 2rem;
  }
}

%subheader-text{
  font-family: Gilroy, "Arial Black", sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #ffffff;
  margin-top: 4rem;
  @include mobileMedia {
    margin-top: 0;
    margin-bottom: 1.4rem;
  }
}

%blockquote{
  margin: 3.6rem 0;
  padding-left: 9.6rem;
  position: relative;
  @include mobileMedia {
    margin: 2.6rem 0;
    padding-left: 5.6rem;
    padding-right: 2rem;
  }
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 2.6rem;
    width: 2.6rem;
    height: 1.9rem;
    background: url("../../../img/blockquote.svg") no-repeat top center;
    background-size: 100% auto;
    z-index: 2;
    @include mobileMedia {
      left: 0.6rem;
    }
  }
}

%blockquote-text{
  font-family: Lato, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  color: $color-text-light;
  margin-bottom: 0.8rem;
}

%blockquote-autor{
  opacity: 0.9;
  font-family: Lato, Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.71;
  color: rgba(#ffffff, 0.9);
}

%link{
  color: $color-blue-10;
  transition: 0.3s color ease-in-out;
  &:hover{
    @media (pointer: fine) {
      color: $color-blue-light-3;
    }
  }
}

.news-article {

  section{
    display: flex;
    margin: 1.6rem 0;
    flex-direction: column;
    justify-content: space-between;
    h1,h2,h3,h4,h5,p,small,a{
      max-width: 19.2rem;
      margin: 0.4rem 0 !important;
    }
  }

  h1{
     @extend %text;
     @extend %header-text
  }

  h2:not(.news-article__text_text){
    @extend %text;
    @extend %header-text;
    font-size: 3.2rem;
    margin-bottom: 3.2rem;
  }
  h3:not(.news-article__text_text){
    @extend %text;
    @extend %header-text;
    font-size: 2.4rem;
    margin-bottom: 1.6rem;
  }
  h4:not(.news-article__text_text){
    @extend %text;
    @extend %header-text;
    font-size: 2rem;
    margin-bottom: 0.8rem;
  }
  h5:not(.news-article__text_text){
    @extend %text;
    @extend %header-text;
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }
  p:not(.news-article__text):not(.news-article__title):not(.news-article__text_strong){
    @extend %text;
  }
  li:not(.news-article__text):not(.news-article__title):not(.news-article__text_strong){
    @extend %text;
  }
  ul{
    li:not(.news-article__text):not(.news-article__title):not(.news-article__text_strong){
      margin-bottom: 1rem;
    }
  }
  small:not(.news-article__text):not(.news-article__title):not(.news-article__text_strong), h6:not(.news-article__text):not(.news-article__title):not(.news-article__text_strong):not(.news-article__text_text){
    @extend %text;
    color: rgba(#ffffff, 0.6);
    font-size: 1.2rem;
  }
  blockquote:not(.news-article__text):not(.news-article__title):not(.news-article__text_strong):not(.news-article__blockquote){
    @extend %text;
    color: rgba(#ffffff, 0.6);
    font-size: 1.2rem;
    font-style: italic;
  }
  a:not(.share__link){
    @extend %text;
    @extend %link;
  }
  a:not(.share__link)[href^='http']::after{
    content: url("../../../img/linkarrow.svg");
    position: relative;
    height: 1.2rem;
    width: 1.2rem;
    bottom: 0.3rem;
    left: 0.3rem;
  }
  img{
    object-fit: cover;
    object-position: center;
    margin: 3.2rem 0;
  }
  
  &__container {
    max-width: 62.4rem;
    margin: 0 auto;
    @include mobileMedia {
      margin: 0 1.6rem 4rem;
    }
  }

  &__list{
    @include mobileMedia {
      padding-bottom: calc(48px - 1.5rem);
    }
    @include desktopMedia {
      margin-bottom: 5.6rem;
    }
  }
  
  &__text-container {
    margin-bottom: 5.6rem;
    @include mobileMedia {
      margin-bottom: 4.8rem;
    }
  }
  
  &__title {
    @extend %header-text;
  }
  
  &__h2 {
    font-family: Gilroy, Arial, sans-serif;
    font-size: 3.2rem;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.44px;
    color: #ffffff;
    margin: 8.2rem 0 2rem;
    @include mobileMedia {
      margin-top: 0;
      margin-bottom: 1.4rem;
    }
  }
  
  &__blockquote {
     @extend %blockquote;
  }
  
  &__blockquote-text {
    @extend %blockquote-text;
  }
  
  &__blockquote-autor {
    @extend %blockquote-autor;
  }
  
  &__text {
    @extend %text;
    &_strong {
      @extend %subheader-text;
    }
  }
  
  &__img-wrapper {
    position: relative;
    overflow: hidden;
    margin: 5.6rem 0;

    @include mobileMedia {
      margin: 0;
    }
  }
  
  &__img {
    width: 100%;
    height: auto;
  
    border-radius: 0.4rem;
    
    &_hidden {
      opacity: 0;
    }
  
    &_visible {
      position: absolute;
      left: 0;
      top: 0;
      transition: 0.3s transform ease-in-out;
    }

    @include mobileMedia {
      margin: 1.8rem 0 3.2rem;
    }
  }
  
  &__share {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 0;
    border-top: 0.2rem solid $color-blue-6;
    border-bottom: 0.2rem solid $color-blue-6;
  }
  
  &__share-text {
    font-family: Gilroy, Arial, sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 0.171rem;
    color: $color-text-light;
    text-transform: uppercase;
  }
  
  &__link {
    @extend %link
  }
}