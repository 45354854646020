@import '../../../scss/common';

.news {
  --newsMardin: 2.4rem;

  @include mobileMedia {
    padding: 8rem 0;
    overflow: hidden;
  }
  &__inner {
    @include container;
    display: flex;
    padding-top: 4rem;
    @include mobileMedia {
      padding-right: 0;
      display: block;
      margin: 0;
      width: 30.4rem;
    }
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {

    @include mobileMedia {
      width: 100%;
      &:last-child{
        margin-right: calc( 26.4rem - 100vw) !important;
      }
    }
  }

  &__item {
    flex: 0 0 auto;
    width: calc(50% - var(--newsMardin) / 2);
    background: rebeccapurple;
    height: 41.6rem;
    position: relative;
    border-radius: 0.4rem;

    @include mobileMedia {
      display: block;
      margin-right: 0;
      width: 28.8rem;
      height: 21.6rem;
    
    }

    &:first-child {
      margin-right: var(--newsMardin);

      @include mobileMedia {
        margin-right: 0;
      }
    }
  }

  &__img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0.4rem; // parent has border too, but it's not here with absolute
  }

  &__item-content {
    padding: 4.8rem;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;

    @include mobileMedia {
      padding: 3.2rem 2.4rem;
    }
  }

  &__item-title {
    font-family: Gilroy, Arial, sans-serif;
    font-size: 3.2rem;
    font-weight: bold;
    line-height: 1.25;
    color: #ffffff;
    margin-bottom: 2.4rem;
    max-width: 32rem;

    @include mobileMedia {
      width: 16rem;
      font-size: 1.6rem;
      line-height: 1.5;
      letter-spacing: normal;
      height: unset;
      margin-bottom: 0.8rem;
    }
  }

  &__item-text {
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1rem;
    color: #ffffff;
    max-width: 27.2rem;

    @include mobileMedia {
      width: 16rem;
      font-size: 1.2rem;
      line-height: 1.67;
      letter-spacing: normal;
    }
  }

  &__item-link {
    position: relative;
    z-index: 2;
    margin-top: auto;
    margin-right: auto;
    margin-left: -0.8rem;

    @include mobileMedia {
      display: none;
    }
  }
}
