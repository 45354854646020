@import "../../../scss/common";

.partner-become {

  &__container {
    max-width: 84rem;
    margin: 0 auto;
    @include mobileMedia {
      margin: 0 1.6rem;
    }
  }
  
  &__title {
    @include subtitle;
    color: white;
    text-align: center;
    margin-bottom: 7rem;

    @include mobileMedia {
      text-align: left;
      margin-bottom: 2.4rem;
    }
  }

  &__items {
    @include mobileMedia {
      margin-bottom: 8rem;
    }
  }
}

