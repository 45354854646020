@import "../../../scss/common";

.features {
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobileMedia {
    flex-wrap: wrap;
    display: grid;
    grid-template: 1fr 1fr/ 1fr 1fr;
    grid-gap: 1.6rem;
    padding: 1.6rem;
    padding-top: 8rem;
  }

  &__item {
    position: relative;
    width: 30rem;
    height: 30rem;
    border-radius: 0.8rem;
    background-image: url("../../../img/shadow.svg"),linear-gradient(to left, $color-blue-4, $color-blue-4);
    background-repeat: no-repeat, no-repeat;
    background-size:19rem 20.8rem, 100%;
    background-position: right top, center;
    background-blend-mode: hard-light;
    margin-right: 2.4rem;
    overflow: hidden;
    --featureItemSize: calc((100vw - 3* 1.6rem)/2);

    @include mobileMedia {
      display: flex;
      width: var(--featureItemSize);
      height: var(--featureItemSize);
      margin-right: 0;
      padding: 1.6rem;
      border-radius: 0.4rem;
    }

    &:last-child {
      margin-right: 0;
    }
    &:before, &:after {
      content: '';
      position: absolute;
      display: block;
    }
    
    &:after {
      width: 3.2rem;
      height: 0.2rem;
      border-radius: 0.1rem;
      background-color: $color-blue-light;
      left: 3.2rem;
      top: 5.9rem;
      z-index: 1;
      @include mobileMedia {
        width: 1.6rem;
        top: 3.5rem;
        left: 1.6rem;
      }
    }
    
    &:before {
      width: 19rem;
      height: 20.8rem;
      top: 0;
      right: 0;
      left: inherit;
      background: no-repeat right top;
      background-size: cover;
      z-index: 1;
      opacity: 0.7;
    }
    
    
  }
  
  &__img {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    width: 9.1rem;
    height: 8.8rem;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;

    @include mobileMedia {
      top: 0.8rem;
      right: 0.8rem;
      width: 6rem;
      height: 5.8rem;
    }
  }
  
  &__title {
    font-family: Gilroy, Arial, sans-serif;
    margin-top: 12rem;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.33;
    color: #ffffff;
    margin-left: 3.2rem;
    margin-bottom: 1.6rem;

    @include mobileMedia {
      bottom: 2.4rem;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 0.05rem;
      width: min-content;
      margin-top: auto;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
  
  &__list {
    font-family: Lato, Arial,sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: normal;
    color: $color-text-light;
    list-style-image: url("../../../img/check-list.svg");
    margin-left: 2.4rem;

    @include mobileMedia {
      display: none;
    }
  }
  
  &__list-item {
    margin: 0.4rem 0;
    padding-left: 0.6rem;
  }
}

.companyPage {
  margin-bottom: 12rem;
  @include mobileMedia {
    margin-bottom: 3.2rem;
  }
}
