@import '../../../scss/common';

.input-status {
    position: relative;
    overflow:hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    height: 2.4rem;
    text-align: center;
    border-radius: 50%;
    color: white;
  
    @include mobileMedia {
      width: 2rem;
      height: 2rem;
    }

    .input-status__value {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: absolute;
        transform: translateY(100%);

        svg {
            height: 100%;
            width: 100%;
        }
    }

    &.is__valid, &.is__error {
      & .input-status__value {
        transform: translateY(0);
        transition: transform .5s .3s ease;
      }
    }

    &.is__error {
      background-color: $color-error;
      animation: scale-easeInElastic .3s forwards;
    }
    
    &.is__valid {
      background-color: $color-valid;
      animation: errorIn .5s;
    }
  }
  
  
  @keyframes errorIn {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  