@import "../../../scss/common";

.company-mission {
    overflow: hidden;
    width: 100%;
    max-width: 840px;
    margin: 0 auto;
    margin-top: 6rem;
    margin-bottom: 14rem;

    @include mobileMedia {
       margin-top: 56px;
       margin-bottom: 3.2rem;
       height: 88rem;
      }

    &__title {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 48px;
        font-family: Gilroy;
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 1px;
        color: #fff;
        margin-bottom: 24px;
    }

    &__text {
        width: calc(100% - 20px);
        margin: 0 auto;
        opacity: 0.9;
        font-family: Lato;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.2px;
        color: #fff;

        @include mobileMedia {
           width: calc(100% - 32px);
        }

        &_item {
            margin-bottom: 15px;
        }
        &_colum {
            display: flex;
            flex-wrap: wrap;
        }
        &_colum-item {
            width: 50%;
            padding-bottom: 20px;
        }
        &_title {
            display: inline-block;
            border-bottom: 1px solid #fff;
        }
    }
}
