@import "breakpoints";

@mixin container {
  width: 100%;
  max-width: 136.6rem;
  margin: 0 auto;
  padding: 0 4.7rem;

  @include mobileMedia {
    padding: 0 1.6rem;
  }

}

@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin svg {
  svg {
    width: 100%;
    height: auto;
  }
}

@mixin img {
  img {
    width: 100%;
    height: auto;
  }
}

@mixin link {
  a {
    display: block;
    height: 100%;
  }
}

@mixin input {
  display: flex;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 1.6rem;
  align-items: center;
  font-family: 'Lato', PTRootUI, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: white;
  transition: 0.2s ease-in-out;
  background-color: $color-blue-11;
  border-radius: 0;

  @include mobileMedia {
    // юзаются пиксели, чтобы избавиться от автозума и "вылет курсора" при фокусе на сафари
    // https://bingoboom.atlassian.net/wiki/spaces/GAM/pages/1315766295/Mobile#Partnership
    // Partnership п.5
    font-size: 16px;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: white;
    box-shadow: 0 0 0px 1000px $color-blue-11 inset;
    -webkit-box-shadow: 0 0 0px 1000px $color-blue-11 inset;
    color: white;
    background-color: $color-blue-11;
    font-family: 'Lato';
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
    caret-color: white;
    background-clip: text;
    
  }
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 100px $color-blue-12 inset;
    -webkit-box-shadow: 0 0 0px 100px $color-blue-12 inset;
  }

  &:hover,
  &:focus {
    @media (pointer: fine) {
      background-color: $color-blue-12;
    }
  }
  &::-webkit-input-placeholder {
    color: $color-text-light;
  }

  &::-webkit-scrollbar {
    background: transparent;
    width: 0.4rem;
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 2px;
    background-color: rgba($color-text-light, 0.3);
  }
}

@mixin field {
  height: 5.6rem;
  border-radius: 0.4rem;
  background-color: $color-blue-11;
  @include mobileMedia {
    height: 4.8rem;
  }
}

@mixin bg-gradient {
  background: linear-gradient(to right, $color-blue-2, $color-blue-1);
}

@mixin bg-gradient-dark {
  background: linear-gradient(to right, $color-blue-7, $color-blue-8);
}

@mixin bg-gradient-dark-hover {
  background: linear-gradient(to right, #0a4899, #032886);
}

@mixin bg-gradient-light {
  background: linear-gradient(to right, $color-blue-light-3, #018ef5);
}

@mixin bg-gradient-main {
  background: linear-gradient(to bottom, rgba(4, 5, 60, 0), $color-main);
}

@mixin border {
  border: solid 2px $color-blue-1;
}

@mixin pseudo-total {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin title-1 {
  font-family: Gilroy, Arial, sans-serif;
  font-size: 5.6rem;
  font-weight: bold;
  letter-spacing: 0.28rem;

  @include mobileMedia {
    font-size: 2.4rem;
    line-height: 1.33;
    letter-spacing: 0.12rem;
  }
}

@mixin title-2 {
  font-family: Gilroy, Arial, sans-serif;
  font-size: 4.8rem;
  font-weight: bold;
  letter-spacing: 0.24rem;
  text-transform: uppercase;
  color: white;

  @include mobileMedia {
    font-size: 3.2rem;
    line-height: 1.25;
    letter-spacing: 0.2rem;
  }
}

@mixin title-3 {
  font-family: Gilroy, Arial, sans-serif;
  font-size: 4.8rem;
  font-weight: bold;
  line-height: 1.17;

  @include mobileMedia {
    font-size: 2.4rem;
    line-height: 1.33;
  }
}

@mixin title-4 {
  @include title-2;
  text-transform: none;
}

@mixin title-5 {
  @include title-2;
  line-height: 1.17;
  letter-spacing: 3px;
}

@mixin subtitle {
  font-family: Gilroy, Arial, sans-serif;
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.1rem;

  @include mobileMedia {
    font-size: 2.4rem;
    line-height: 1.33;
    letter-spacing: normal;
  }
}

@mixin text-1 {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.5;
}

@mixin text-2 {
  font-size: 0.8rem;
  letter-spacing: 0.57px;
}

@mixin text-3 {
  font-size: 1.43rem;
  font-weight: 500;
  color: #ffffff;
}

@mixin text-4 {
  font-family: Lato, Arial, sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.08rem;
}

@mixin text-5 {
  font-family: PTRootUI, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 500;
  
  @include mobileMedia {
    font-family: Lato, Arial, sans-serif;
    font-size: 1.2rem;
    line-height: 1.67;
  }
}

@mixin text-6 {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.08rem;
}

@mixin text-hover {
  color: white;
  transition: 0.2s color ease-in-out;
  &:hover {
    @media (pointer: fine) {
      color: $color-text-light;
    }
  }
}

@mixin text-hover-bright {
  color: white;
  transition: 0.2s color ease-in-out;
  &:hover {
    @media (pointer: fine) {
      color: $color-blue-10;
    }
  }
  &:active {
    color: $color-blue-10;
  }
}

@mixin text-hover-bright-yellow {
  @include text-hover-bright;
  &:hover {
    @media (pointer: fine) {
      color: $color-yellow-2;
    }
  }
}

@mixin text-hover-turn {
  color: $color-text-light;
  transition: 0.2s color ease-in-out;
  &:hover {
    @media (pointer: fine) {
      color: white;
    }
  }
}

@keyframes scale-easeInElastic {
	0% {
		transform: scale(0);
	}
  25% {
		transform: scale(0.8);
	}
  50% {
		transform: scale(1.1);
	}
  75% {
		transform: scale(1.3);
	}
  100% {
		transform: scale(1);
	}
}

