@import "../../../scss/common";
.close-button {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    @include bg-gradient-dark;
    display: flex;
    align-items: center;
    justify-content: center;
  
    svg {
      transition: 0.3s fill ease-in-out;
      fill: #a2b6ef;
    }
  
    &:hover svg {
      @media (pointer: fine) {
        fill: white;
      }
    }
  
    &_game {
      &_keno {
        background: #330286;
        svg {
          fill: #ece3fb;
        }
      }
  
      &_ler {
        background: #333333;
        svg {
          fill: white;
        }
      }

      &_ar, &_bj, &-cr, &_bc {
        background: #151617;
        svg {
          fill: white;
        }
      }
    }
}
