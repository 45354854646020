@import "../../../scss/common";

.checkbox {

  display: flex;

  &__label {
    position: relative;
    display: inline-flex;
    margin-right: 2rem;

    @include mobileMedia {
      margin-right: 1.2rem;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__check {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.4rem;
    background: $color-blue-11;
    transition: 0.2s background ease-in-out;

    .checkbox__input:focus ~ &,
    .checkbox__input:hover ~ & {
      @media (pointer: fine) {
        background: $color-blue-12;
      }
    }
    .checkbox__input:checked ~ & {
      background: url("../../../img/checkbox-active.svg") $color-blue-11;
      background-size: contain;
    }
  }

  &__label-text {
    font-family: Lato, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
    transition: 0.2s color ease-in-out;
    color: $color-text-light;

    @include mobileMedia {
      font-size: 1.4rem;
    }

    &:hover, &:focus {
      cursor: pointer;
      color: white;
    }
  }
}
